
// ACCORDION
import { Collapse } from 'antd';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function AccordionPricing(props) {

    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [currentPrice, setPrice] = useState(null);
    const [currentFeatures, setFeatures] = useState(null);

    // # UTILS
    const { Panel } = Collapse;

    useEffect(() => {
        if (!userStore.clientData.language.id) { return }
        var price = props.data.priceTranslations.filter(item => item.languageId === userStore.clientData.language.id);
        var features = props.data.featuresTranslations.filter(item => item.languageId === userStore.clientData.language.id);
        setPrice(price)
        setFeatures(features)
    }, [userStore.clientData]);

    const claimedSvg = <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11.5" cy="12.125" r="8.625" fill="white" />
        <path d="M23 11.4062L20.4491 8.54152L20.8045 4.75268L17.0305 3.91071L15.0545 0.625L11.5 2.12411L7.94545 0.625L5.96955 3.90045L2.19545 4.73214L2.55091 8.53125L0 11.4062L2.55091 14.271L2.19545 18.0701L5.96955 18.9121L7.94545 22.1875L11.5 20.6781L15.0545 22.1772L17.0305 18.9018L20.8045 18.0598L20.4491 14.271L23 11.4062ZM9.50318 16.2527L5.53045 12.3406L7.07773 10.821L9.50318 13.2134L15.6191 7.18616L17.1664 8.7058L9.50318 16.2527Z" fill="#F09023" />
    </svg>;
    const greyclaimedSvg = <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11.5" cy="12.125" r="8.625" fill="white" />
        <path d="M23 11.4062L20.4491 8.54152L20.8045 4.75268L17.0305 3.91071L15.0545 0.625L11.5 2.12411L7.94545 0.625L5.96955 3.90045L2.19545 4.73214L2.55091 8.53125L0 11.4062L2.55091 14.271L2.19545 18.0701L5.96955 18.9121L7.94545 22.1875L11.5 20.6781L15.0545 22.1772L17.0305 18.9018L20.8045 18.0598L20.4491 14.271L23 11.4062ZM9.50318 16.2527L5.53045 12.3406L7.07773 10.821L9.50318 13.2134L15.6191 7.18616L17.1664 8.7058L9.50318 16.2527Z" fill="#808080" />
    </svg>;


    return (
        <>

            <Collapse
                accordion
                className={`bg-white accordion accordion-default accordion-pricing`} expandIconPosition="end">
                <Panel header={
                    <>
                        <div className="pricing-head d-flex flex-row">
                            <div>
                                <span className="pr-2">
                                    {props.accountType === null ? claimedSvg : props.accountType === undefined ? claimedSvg : parseInt(props.data.accountTypeId) <= parseInt(props.accountType) ? claimedSvg : greyclaimedSvg}
                                </span>
                                <span className="pr-2 fw-bold">{props.data.name ? props.data.name : "title"}</span>
                            </div>
                            <span dangerouslySetInnerHTML={{ __html: currentPrice === null ? null : currentPrice[0].text }}></span>
                        </div>
                    </>
                }>

                    <div className="d-flex flex-row flex-wrap ">
                        <div className="pricing-card w-50 mobile-friendly d-flex justify-content-center align-items-center">
                            <div className="card">
                                <div className="mb-4 text-center">
                                    <h2 className="mb-2 text-uppercase">{props.data.name}</h2>
                                    <div className="pricing-price fs-xregular mb-3" dangerouslySetInnerHTML={{ __html: currentPrice === null ? null : currentPrice[0].text }}></div>
                                    {
                                        parseInt(props.data.accountTypeId) <= parseInt(props.accountType) ?
                                            <div className='font-special'>{claimedSvg} {t("business_main_claimed")}</div>
                                            :
                                            !props.PM_PlanEdit ? null :
                                                <Link className="btn btn-special d-inline-block" to={"/company/plans/upgrade" + "?plan=" + props.data.name.toLowerCase()}>
                                                    {t("business_extra_signup")}
                                                </Link>
                                    }

                                </div>
                                <div>{t("business_unknown5")}</div>
                            </div>
                        </div>
                        <div className="plan-features w-50 mobile-friendly">
                            <ul dangerouslySetInnerHTML={{ __html: currentFeatures === null ? null : currentFeatures[0].text }}></ul>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </>
    )
}
var PlanExampleObject = {
    head: [
        {
            id: 201,
            sort: 1,
            title: "discard_pricing_head_overview",
            features: [
                "discard_pricing_feature_users",
                // "discard_pricing_feature_dashboards",
                // "discard_pricing_feature_jobs"
            ]
        },
        {
            id: 202,
            sort: 2,
            title: "discard_pricing_head_platform_features",
            features: [
                "discard_pricing_feature_average_score",
                "discard_pricing_feature_experience_score",
                // "discard_pricing_feature_promises",
                "discard_pricing_feature_application_information",
                "discard_pricing_feature_survey_company",
                "discard_pricing_feature_procedure",
                "discard_pricing_feature_messages_functionality",
                "discard_pricing_feature_company_info",
                "discard_pricing_feature_jobs", // new
                "discard_pricing_feature_analytics_dashboard", // new
            ]
        },
        // {
        //     id: 203,
        //     sort: 3,
        //     title: "discard_pricing_head_analytics",
        //     features: [
        //         "discard_pricing_feature_analytics_dashboard",
        //         "discard_pricing_feature_roles"
        //     ]
        // },
        {
            id: 204,
            sort: 4,
            title: "discard_pricing_head_support",
            features: [
                "discard_pricing_feature_support",
                "discard_pricing_feature_training",
                "discard_pricing_feature_languages"
            ]
        },
    ],
    plans: [
        {
            id: 101,
            guid: "f75776c9-9e41-4ee3-bc5f-a931af15033a",
            int: 20,
            sort: 1,
            level: "b",
            title: "discard_pricing_level_b_name",
            price: "discard_pricing_request",
            description: "discard_pricing_level_b_description",
            // features per category
            features: {
                "201": ["2"],
                // "201": ["2", true, "discard_pricing_level_b_feature_jobs"],
                "202": [true, true, true, true, true, true, true, true, true],
                // "202": [true, true, true, true, true, true, true, true],
                // "203": [true, true],
                "204": ["discard_pricing_content_support", "discard_pricing_level_b_feature_training", "discard_pricing_content_languages"],
            }
        },
        {
            id: 102,
            guid: "21307b39-e3f2-4008-bafa-7a34ba91251a",
            int: 100,
            sort: 2,
            level: "c",
            title: "discard_pricing_level_c_name",
            price: "discard_pricing_request",
            description: "discard_pricing_level_c_description",
            features: {
                "201": ["3"],
                // "201": ["3", true, "discard_pricing_level_c_feature_jobs"],
                "202": [true, true, true, true, true, true, true, true, true],
                // "202": [true, true, true, true, true, true, true, true],
                // "203": [true, true],
                "204": ["discard_pricing_content_support", "discard_pricing_level_c_feature_training", "discard_pricing_content_languages"],
            }
        },
        {
            id: 103,
            guid: "f089f68e-92dc-4a6a-907b-0428a2fe8b3b",
            int: 200,
            sort: 3,
            level: "d",
            title: "discard_pricing_level_d_name",
            price: "discard_pricing_request",
            description: "discard_pricing_level_d_description",
            features: {
                "201": ["4"],
                // "201": ["4", true, "discard_pricing_level_d_feature_jobs"],
                "202": [true, true, true, true, true, true, true, true, true],
                // "202": [true, true, true, true, true, true, true, true],
                // "203": [true, true],
                "204": ["discard_pricing_content_support", "discard_pricing_level_d_feature_training", "discard_pricing_content_languages"],
            }
        },
        {
            id: 104,
            guid: "e4db5aa5-b959-4cda-8526-d887ab9713b1",
            int: 300,
            sort: 4,
            level: "e",
            title: "discard_pricing_level_e_name",
            price: "discard_pricing_request",
            description: "discard_pricing_level_e_description",
            features: {
                "201": ["discard_pricing_request"],
                // "201": ["discard_pricing_request", true, false],
                "202": [true, true, true, true, true, true, true, true, true],
                // "202": [true, true, false, true, true, true, false, true],
                // "203": [true, true],
                "204": ["discard_pricing_content_support", "discard_pricing_level_e_feature_training", "discard_pricing_content_languages"],
            }
        },
    ]
};

export default PlanExampleObject;
import Panel from "components/modules/panel/panel";
import { useGetDbExperienceScale } from "components/requests/dashboard/getDbExperienceScale";
import { useGetDbExperienceScores } from "components/requests/dashboard/getDbExperienceScores";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { useGetDbMilestoneData } from "components/requests/dashboard/getDbMilestoneData";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { hasPermission } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useGetCompanyShareData } from "pages/company/company-share";
import { Fragment, useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../dashboard-layout";
import BrandExperiencePanel from "../panels/brand-experience-panel";
import CandidateExperiencePanel from "../panels/candidate-experience-panel";
import AttractivenessDetailsPanelContent from "../panels/content/attractiveness-details";
import ExperienceScalePanel from "../panels/experience-scale-panel";
import HotTalentPanel from "../panels/hot-talent-panel";
import MessagesPanel from "../panels/messages-panel";
import MilestonesPanel from "../panels/milestones-panel";
import RatingAmountPanel, { settingsRatingAmountModalContent } from "../panels/rating-amount-panel";
import ScoreEvolutionPanel from "../panels/score-evolution-panel";
import { Modal, Select } from "antd"
import { adminRoleId } from "components/utils/methods/AppMethods";
import CandidateBrandExperiencePanel from "../panels/candidate-brand-experience-panel";
import Rating from "components/modules/rating/rating";
import { useQuery } from "@tanstack/react-query";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import Loading from "components/animations/loading";
import NoData from "components/layouts/no-data/nodata";

export default function DashboardPremium(props) {
    // # STORES
    const userStore = useUserStore();
    const navigate = useNavigate();
    const isAdmin = userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === adminRoleId.toLowerCase() ? true : false;

    // # CLIENT STATES
    const [showAttractivenessDetails, setShowAttractivenessDetails] = useState(false);
    const [filterUserId, setFilterUserId] = useState(null);
    const [filterPhaseId, setFilterPhaseId] = useState(null);
    const [showCeKeysModal, setShowCeKeysModal] = useState(false);


    // # SERVER STATES
    // base data
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ refetchOnMountOrArgChange: true, enabled: true }); // company data (number of ratings, rating, companyname, planname)
    const { data: dbExperienceScaleFeedback, isLoading: dbExperienceScaleFeedbackIsLoading, isError: dbExperienceFeedbackIsError, refetch: dbExperienceScaleFeedbackRefetch, isRefetching: dbExperienceScaleFeedbackIsRefetching } = useGetDbExperienceScale({ category: "Feedback", key: "get-BC_Feedback", refetchOnMountOrArgChange: true });
    const { data: dbExperienceScaleInformation, isLoading: dbExperienceScaleInformationIsLoading, isError: dbExperienceScaleInformationIsError, refetch: dbExperienceScaleInformationRefetch, isRefetching: dbExperienceScaleInformationIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Information", key: "get-BC_Information", refetchOnMountOrArgChange: true });
    const { data: dbExperienceScaleSpeed, isLoading: dbExperienceScaleSpeedIsLoading, isError: dbExperienceScaleSpeedIsError, refetch: dbExperienceScaleSpeedRefetch, isRefetching: dbExperienceScaleSpeedIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Speed", key: "get-BC_Speed", refetchOnMountOrArgChange: true });
    const { data: dbExperienceScaleAtmosphere, isLoading: dbExperienceScaleAtmosphereIsLoading, isError: dbExperienceScaleAtmosphereIsError, refetch: dbExperienceScaleAtmosphereRefetch, isRefetching: dbExperienceScaleAtmosphereIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Atmosphere", key: "get-BC_Atmosphere", refetchOnMountOrArgChange: true });
    const { data: dbExperienceScaleAttractiveness, isLoading: dbExperienceScaleAttractivenessIsLoading, isError: dbExperienceScaleAttractivenessIsError, refetch: dbExperienceScaleAttractivenessRefetch, isRefetching: dbExperienceScaleAttractivenessIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Attractiveness", key: "get-BC_Attractiveness", refetchOnMountOrArgChange: true });
    const { data: dbExperienceScaleJobAttractiveness, isLoading: dbExperienceScaleJobAttractivenessIsLoading, isError: dbExperienceScaleJobAttractivenessIsError, refetch: dbExperienceScaleJobAttractivenessRefetch, isRefetching: dbExperienceScaleJobAttractivenessIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "JobAttractiveness", key: "get-BC_JobAttractiveness", refetchOnMountOrArgChange: true });
    const { data: dbExperienceScaleBrandOnline, isLoading: dbExperienceScaleBrandOnlineIsLoading, isError: dbExperienceScaleBrandOnlineIsError, refetch: dbExperienceScaleBrandOnlineRefetch, isRefetching: dbExperienceScaleBrandOnlineIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Brand", key: "get-BC_BrandOnline", refetchOnMountOrArgChange: true });
    const { data: dbExperienceScaleSalary, isLoading: dbExperienceScaleSalaryIsLoading, isError: dbExperienceScaleSalaryIsError, refetch: dbExperienceScaleSalaryRefetch, isRefetching: dbExperienceScaleSalaryIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Salary", key: "get-BC_Salary", refetchOnMountOrArgChange: true });
    const { data: dbExperienceScaleRecurrency, isLoading: dbExperienceScaleRecurrencyIsLoading, isError: dbExperienceScaleRecurrencyIsError, refetch: dbExperienceScaleRecurrencyRefetch, isRefetching: dbExperienceScaleRecurrencyIsRefetching } = useGetDbExperienceScale({ filterUserId, filterPhaseId, category: "Recurrency", key: "get-BC_Recurrency", refetchOnMountOrArgChange: true });
    const { data: dbScoreEvolution, isLoading: dbScoreEvolutionIsLoading, isError: dbScoreEvolutionIsError, refetch: dbScoreEvolutionRefetch, isRefetching: dbScoreEvolutionIsRefetching } = useGetDbScoreEvolution({ filterPhaseId, filterUserId, category: "General", key: "getScore-BC_General", refetchOnMountOrArgChange: true });
    const { data: dbScoreEvolutionCandidate, isLoading: dbScoreEvolutionCandidateIsLoading, isError: dbScoreEvolutionCandidateIsError, refetch: dbScoreEvolutionCandidateRefetch, isRefetching: dbScoreEvolutionCandidateIsRefetching } = useGetDbScoreEvolution({ filterPhaseId, filterUserId, category: "Experience", key: "getScore-BC_CandidateExperience", refetchOnMountOrArgChange: true });
    const { data: dbScoreEvolutionBrand, isLoading: dbScoreEvolutionBrandIsLoading, isError: dbScoreEvolutionBrandIsError, refetch: dbScoreEvolutionBrandRefetch, isRefetching: dbScoreEvolutionBrandIsRefetching } = useGetDbScoreEvolution({ filterPhaseId, filterUserId, category: "Appearance", key: "getScore-BC_EmployerBrand", refetchOnMountOrArgChange: true });
    const { data: dbScoreEvolutionAttractive, isLoading: dbScoreEvolutionAttractiveIsLoading, isError: dbScoreEvolutionAttractiveIsError, refetch: dbScoreEvolutionAttractiveRefetch, isRefetching: dbScoreEvolutionAttractiveIsRefetching } = useGetDbScoreEvolution({ category: "Attractiveness", key: "getScore-BC_Attractiveness", isCeKey: false, isWeekNotation: false, useDevisions: false, refetchOnMountOrArgChange: true });
    const { data: dbScoreEvolutionJobAttractive, isLoading: dbScoreEvolutionJobAttractiveIsLoading, isError: dbScoreEvolutionJobAttractiveIsError, refetch: dbScoreEvolutionJobAttractiveRefetch, isRefetching: dbScoreEvolutionJobAttractiveIsRefetching } = useGetDbScoreEvolution({ category: "JobAttractiveness", key: "getScore-BC_JobAttractiveness", isCeKey: false, isWeekNotation: false, useDevisions: false, refetchOnMountOrArgChange: true });
    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching, refetch: dbMessagesRefetch } = useGetDbMessages({ filterPhaseId, filterUserId, refetchOnMountOrArgChange: true });

    const { data: jobProbeStats } = useQuery({
        queryKey: ["jobProbeQuestionsStatistics"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/probes/stats`,
            body: {
                BusinessId: userStore.companyData.id,
                FilterJobId: null
            }
        }),
        enabled: !!isAdmin
    })

    const { data: recruitersData, isLoading: recruitersDataIsLoading, isSuccess: recruitersDataIsSuccess } = useQuery({
        cacheTime: 0,
        queryKey: ["dbGetAllRecruiters"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/recruiters`,
            body: {
                BusinessId: userStore.companyData.id
            },
            callback: (res) => {
                if (res && res.data && res.data.condition === 290) { return [] }
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    if (res.data.instance.toLowerCase() === "no data") {
                        return []
                    }
                    else {
                        var newArray = []
                        res.data.instance.map((item) => {
                            if (isAdmin) {
                                newArray.push({
                                    label: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`,
                                    value: item.id,
                                    raw: item
                                })
                            } else {
                                if (item.id === userStore.userData.id) {
                                    setFilterUserId(item.id);
                                    newArray.push({
                                        label: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`,
                                        value: item.id,
                                        raw: item
                                    })
                                }
                            }
                        })

                        return newArray;
                    }
                }
                else {
                    return []
                }
            }
        })
    })

    const { data: phasesData, isLoading: phasesDataIsLoading } = useQuery({
        queryKey: ["dbGetAllPhases"],
        queryFn: () => sendAxiosGetRequest({
            route: `v2/generic/catalogues/JobPhases`,
            callback: (res) => {
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    return res.data.instance.map((item) => {
                        return {
                            label: t(item.translationKey),
                            value: item.id,
                            raw: item
                        }
                    })
                }
            }
        })
    })
    const { data: ceKeysData, isLoading: ceKeysDataIsLoading } = useQuery({
        queryKey: ["dbGetAllCEKeys"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/agencies`,
            body: {
                BusinessId: userStore.companyData.id
            },
        }),
        enabled: !!(userStore.companyData && userStore.companyData.id),
    })

    useEffect(() => {
        if (recruitersDataIsSuccess) {
            refetch();
            dbExperienceScaleFeedbackRefetch();
            dbExperienceScaleInformationRefetch();
            dbExperienceScaleSpeedRefetch();
            dbExperienceScaleAtmosphereRefetch();
            dbExperienceScaleAttractivenessRefetch();
            dbExperienceScaleJobAttractivenessRefetch();
            dbExperienceScaleBrandOnlineRefetch();
            dbExperienceScaleSalaryRefetch();
            dbExperienceScaleRecurrencyRefetch();
            dbScoreEvolutionRefetch();
            dbScoreEvolutionCandidateRefetch();
            dbScoreEvolutionBrandRefetch();
            dbScoreEvolutionAttractiveRefetch();
            dbScoreEvolutionJobAttractiveRefetch();
            dbMessagesRefetch();
        }
    }, [filterUserId, filterPhaseId, recruitersDataIsSuccess]);


    const showRatingSettings = userStore.flagData.toggleScorePraiseVisible || userStore.flagData.toggleScoreStarVisible /* || userStore.flagData.toggleScoreTextVisible */;
    // const ratingsOptions = !showRatingSettings ? null :
    //     [{
    //         element: <>{t("business_dashboard_panel_option_settings")}</>,
    //         modalContent: settingsRatingAmountModalContent
    //     }];
    const ratingsOptions = null;

    return <>
        <DashboardLayout data={dbGeneralData} type={t("business_dashboard_type_premium")}>
            <Modal
                open={showCeKeysModal}
                onCancel={() => setShowCeKeysModal(false)}
                title={t("business_dashboard_ce_keys_modal_title")}
                footer={null}
            >
                <>
                    <div className="flex flex-column align-items-start" style={{ gap: 5 }}>
                        {
                            ceKeysData && ceKeysData.length > 0 && ceKeysData.map((item, index) => {
                                return <Link key={index} to={`/ce-dashboard?id=${item.id}`} className="link mb-0">{item.name}</Link>
                            })
                        }

                    </div>
                </>

            </Modal>
            {/* ### BASE ###  */}
            {
                recruitersDataIsLoading ? <Loading center /> :
                    !isAdmin && recruitersData && recruitersData.length === 0 ? <NoData wise="smile" text={t("business_dashboard_no_dashboard_for_non_admin")} /> :
                        <>
                            <section className="row mb-10">
                                <div className="flex flex-row align-items-start justify-content-between mb-4">

                                    {
                                        dbGeneralData && <div className="flex flex-column align-items-start" style={{ gap: 10 }}>
                                            <div className="flex flex-row align-items-center" style={{ gap: 10 }}>
                                                <h2 className="mb-0 fw-bold">{dbGeneralData && dbGeneralData.totalCount ? dbGeneralData.totalCount : "-"} {t("business_dashboard_total_score")}</h2>
                                                <Rating rating={!dbGeneralData ? 0 : !dbGeneralData.scoreGeneral ? null : dbGeneralData.scoreGeneral} noamount />
                                            </div>
                                            {
                                                isAdmin && ceKeysData && ceKeysData.length > 0 && <p
                                                    className="link mb-0 inline-block"
                                                    onClick={() => setShowCeKeysModal(true)}>
                                                    {t("business_dashboard_show_ce_keys_modal")}
                                                </p>
                                            }

                                        </div>
                                    }
                                    <div className="flex flex-row align-items-center flex-nowrap" style={{ gap: 5 }}>
                                        {
                                            recruitersData && isAdmin &&
                                            <Select
                                                className="w-100"
                                                defaultValue={null}
                                                style={{ minWidth: 300 }}
                                                loading={recruitersDataIsLoading}
                                                onChange={(value) => {
                                                    if (value === null) setFilterUserId(null);
                                                    else setFilterUserId(value);
                                                }}
                                                options={recruitersData ? [{ label: t("business_dashboard_filter_select_all_recruiters"), value: null }, ...recruitersData] : []}
                                            />
                                        }
                                        {
                                            phasesData &&
                                            <Select
                                                className="w-100"
                                                defaultValue={null}
                                                style={{ minWidth: 300 }}
                                                loading={phasesDataIsLoading}
                                                onChange={(value) => {
                                                    if (value === null) setFilterPhaseId(null);
                                                    else setFilterPhaseId(value);
                                                }}
                                                options={phasesData ? [{ label: t("business_dashboard_filter_select_all_phases"), value: null }, ...phasesData] : []}
                                            />
                                        }
                                    </div>
                                </div>


                                {
                                    isAdmin && <div className="col gx-2">
                                        <ScoreEvolutionPanel
                                            data={[
                                                {
                                                    id: t("business_dashboard_average_score"),
                                                    data: dbScoreEvolution
                                                },
                                                {
                                                    id: t("business_dashboard_average_score_candidate"),
                                                    data: dbScoreEvolutionCandidate
                                                },
                                                {
                                                    id: t("business_dashboard_average_score_brand"),
                                                    data: dbScoreEvolutionBrand
                                                },
                                            ]}
                                            panelProps={{
                                                isLoading: (dbScoreEvolutionCandidateIsLoading || dbScoreEvolutionBrandIsLoading),
                                                isError: (dbScoreEvolutionCandidateIsError || dbScoreEvolutionBrandIsError),
                                                isRefetching: (dbScoreEvolutionCandidateIsRefetching || dbScoreEvolutionBrandIsRefetching),

                                                // HANNE TEAMS groupchat - disablen voor nu
                                                // options: [
                                                //     {
                                                //         element: <>{t("business_dashboard_panel_option_improve")}</>,
                                                //         modalContent: howToImproveScoreEvolutionModalContent
                                                //     }
                                                // ]
                                            }}
                                        />
                                    </div>
                                }

                            </section>
                            {/* ### APPLICANT EXPERIENCE ###  */}
                            <section>
                                <h1 className="title mb-4">{t("business_dashboard_section_experience_title")}</h1>
                                <>
                                    <div className="row">
                                        <div className="col gx-2">
                                            <CandidateBrandExperiencePanel
                                                filterPhaseId={filterPhaseId}
                                                filterUserId={filterUserId}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="row">
                        <div className="col gx-2">
                            <CandidateExperiencePanel
                                data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                panelProps={{
                                    isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    // HANNE TEAMS groupchat - disablen voor nu
                                    // options: [
                                    //     {
                                    //         element: <>{t("business_dashboard_panel_option_improve")}</>,
                                    //         modalContent: howToImproveCandidateExperienceModalContent
                                    //     }
                                    // ]
                                }}
                            />
                        </div>
                        <div className="col gx-2">
                            <BrandExperiencePanel
                                data={!dbExperienceScores ? null : dbExperienceScores[0]}
                                panelProps={{
                                    isLoading: dbExperienceScoresIsLoading || dbExperienceScoresIsRefetching, isError: dbExperienceScoresIsError,
                                    options: [
                                        // HANNE TEAMS groupchat - disablen voor nu
                                        // {
                                        //     element: <>{t("business_dashboard_panel_option_improve")}</>,
                                        //     modalContent: howToImproveBrandExperienceModalContent
                                        // },
                                        {
                                            element: <>{showAttractivenessDetails ? t("business_dashboard_panel_option_details_hide") : t("business_dashboard_panel_option_details")}</>,
                                            onClick: () => {
                                                var res = !showAttractivenessDetails;
                                                setShowAttractivenessDetails(res);
                                                // if (res === true) {
                                                //     setTimeout(() => {
                                                //         const id = document.getElementById("attractive-details");
                                                //         id.scrollIntoView({ behavior: 'smooth' }, true);
                                                //     }, 100);
                                                // }
                                            }
                                        }
                                    ]
                                }}
                            />
                        </div>
                    </div> */}
                                    {
                                        !showAttractivenessDetails ? null :
                                            <div className="row">
                                                <div className="col gx-2">
                                                    <Panel
                                                        anchorid={"attractive-details"}
                                                        title={<><div className="flex justify-content-between">
                                                            <span>{t("business_main_details")}: {t("business_dashboard_panel_brand_experience_title")}</span>
                                                            <span className="font-special color-darkgrey hover" onClick={() => setShowAttractivenessDetails(false)}>
                                                                <span className="icon icon-cross-round icon-nospace mr-1"></span>
                                                                {t("business_main_close")}</span>
                                                        </div></>}
                                                    >
                                                        <AttractivenessDetailsPanelContent
                                                            data={[
                                                                {
                                                                    id: t("business_dashboard_jobattractiveness"),
                                                                    data: dbScoreEvolutionJobAttractive
                                                                },
                                                                {
                                                                    id: t("business_dashboard_brandattractiveness"),
                                                                    data: dbScoreEvolutionAttractive
                                                                },
                                                            ]}
                                                            isLoading={(dbScoreEvolutionAttractiveIsLoading || dbScoreEvolutionJobAttractiveIsLoading)}
                                                            isError={(dbScoreEvolutionAttractiveIsError || dbScoreEvolutionJobAttractiveIsError)}
                                                            isRefetching={(dbScoreEvolutionAttractiveIsRefetching || dbScoreEvolutionJobAttractiveIsRefetching)}
                                                        />
                                                    </Panel>
                                                </div>
                                            </div>
                                    }
                                    {
                                        isAdmin && jobProbeStats && jobProbeStats.length > 0 && <>
                                            <div className="row">
                                                <div className="col gx-2">
                                                    <Panel
                                                        title={t("business_dashboard_panel_job_probe_stats_title")}
                                                    >

                                                        <div className="d-grid p-4" style={{
                                                            // gridTemplateRows: "repeat(5, 100px)",
                                                            gridTemplateColumns: "repeat(4, 25%)",
                                                            gap: "10px",
                                                        }}>
                                                            {
                                                                jobProbeStats.sort((a, b) => b.percentage - a.percentage).map((item, key) => {
                                                                    return <Fragment key={key}>
                                                                        <div className="grid-span-1">
                                                                            <p className={`fs-small color-grey mb-2 `}>{t(item.name)}</p>
                                                                            <p className={`fs-large mb-2 mx-2`}>
                                                                                {(item.percentage && item.percentage.toFixed(2)) || 0}%
                                                                            </p>
                                                                        </div>
                                                                    </Fragment>
                                                                })
                                                            }
                                                        </div>
                                                    </Panel>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        isAdmin &&
                                        <div className="row">
                                            <div className="col gx-2">
                                                <ExperienceScalePanel
                                                    data={[
                                                        // {
                                                        //     id: "BC_General",
                                                        //     label: t("business_dashboard_panel_experience_scale_filter_all"),
                                                        //     data: dbExperienceScaleGeneral,
                                                        // },
                                                        // {
                                                        //     id: "BC_CandidateExperience",
                                                        //     label: t("business_dashboard_panel_experience_scale_filter_candidate_experience"),
                                                        //     data: dbExperienceScaleCandidateExperience,
                                                        // },
                                                        {
                                                            id: "Atmosphere",
                                                            label: t("business_dashboard_panel_experience_scale_filter_atmosphere"),
                                                            data: dbExperienceScaleAtmosphere,
                                                        },
                                                        {
                                                            id: "Information",
                                                            label: t("business_dashboard_panel_experience_scale_filter_information"),
                                                            data: dbExperienceScaleInformation,
                                                        },
                                                        {
                                                            id: "Speed",
                                                            label: t("business_dashboard_panel_experience_scale_filter_speed"),
                                                            data: dbExperienceScaleSpeed,
                                                        },
                                                        {
                                                            id: "Feedback",
                                                            label: t("business_dashboard_panel_experience_scale_filter_feedback"),
                                                            data: dbExperienceScaleFeedback,
                                                        },
                                                        {
                                                            id: "Salary",
                                                            label: t("business_dashboard_panel_experience_scale_filter_salary"),
                                                            data: dbExperienceScaleSalary,
                                                        },
                                                        {
                                                            id: "Recurrency",
                                                            label: t("business_dashboard_panel_experience_scale_filter_recurrency"),
                                                            data: dbExperienceScaleRecurrency,
                                                        },
                                                        // {
                                                        //     id: "EmployerBrand",
                                                        //     label: t("business_dashboard_panel_experience_scale_filter_employerbrand"),
                                                        //     data: dbExperienceScaleEmployerBrand,
                                                        // },
                                                        {
                                                            id: "Attractiveness",
                                                            label: t("business_dashboard_panel_experience_scale_filter_attractiveness"),
                                                            data: dbExperienceScaleAttractiveness,
                                                        },
                                                        {
                                                            id: "JobAttractiveness",
                                                            label: t("business_dashboard_panel_experience_scale_filter_jobattractiveness"),
                                                            data: dbExperienceScaleJobAttractiveness,
                                                        },
                                                        {
                                                            id: "Brand",
                                                            label: t("business_dashboard_panel_experience_scale_filter_branonline"),
                                                            data: dbExperienceScaleBrandOnline,
                                                        },
                                                    ]}
                                                    // isError={dbExperienceScaleGeneralIsError || dbExperienceFeedbackIsError}
                                                    isError={dbExperienceFeedbackIsError}
                                                    // isLoading={dbExperienceScaleGeneralIsLoading || dbExperienceScaleFeedbackIsLoading}
                                                    isLoading={dbExperienceScaleFeedbackIsLoading}
                                                    isRefetching={dbExperienceScaleFeedbackIsRefetching}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col gx-2">
                                            <MessagesPanel
                                                data={dbMessages}
                                                panelProps={{
                                                    isLoading: dbMessagesIsLoading || dbMessagesIsRefetching, isError: dbMessagesIsError,
                                                    isRefetching: dbMessagesIsRefetching,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        isAdmin && <HotTalentPanel
                                            filterPhaseId={filterPhaseId}
                                            filterUserId={filterUserId}
                                        />
                                    }
                                </>

                            </section>
                        </>
            }
        </DashboardLayout>
    </>
}
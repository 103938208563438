import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useAddTipReply = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateAddTipReply"],
        mutationFn: (propsFn) => axios.post(`v5/insert/feedback/answer`, {
            UserId: userStore.userData.id,
            FeedbackId: propsFn.tipId,
            Answer: props.newTipMessage,
        }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                props.setNewTipMessage("");
                if (props.setMessages) {
                    props.setMessages(false);
                }

                setTimeout(() => {
                    props.getFeedbackMessages();
                }, 200);
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true, }),
    })
}



export default useAddTipReply
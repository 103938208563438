import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useEditLanguage from 'components/requests/account/useEditLanguage';
import { useGetLanguages, useLanguagesStore } from "components/utils/stores/languagesStore";
import { useUserStore } from 'components/utils/stores/userStore';
import i18n, { t } from 'i18next';
import $ from "jquery";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import wiseLogo from '../../../assets/images/Logo.png';
import envir from "../../../env.json";
import Loading from "../../animations/loading";
import { handleRequestError, handleRequestSuccess, hasPermission } from "../../utils/methods/MyMethods";
import OverlayHeaderMenu from "../overlays/overlayHeaderMenu";
import HeaderAnnouncement from "./announcement";
import { Select } from 'antd';

function Header(props) {
  // # STORES
  const langsStore = useLanguagesStore();
  const userStore = useUserStore();

  // # CLIENT STATES
  const [PM_PlanEdit, setPM_PlanEdit] = useState(false);
  const [announcement, setAnnouncement] = useState(null)

  // # SERVER STATES
  const { isLoading: languagesIsLoading } = useGetLanguages();
  const mutateLanguage = useEditLanguage();
  const { } = useGetHeadAlert({ setAnnouncement })

  // # UTILS
  const navigate = useNavigate();
  const urlParam = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (urlParam.get("lng") && urlParam.get("lng") === "keys") {
      setTimeout(() => {
        i18n.changeLanguage("cimode")
      }, [1000])
    }
  }, [urlParam.get("lng")])

  function LanguageSelect() {
    return <>
      {

        !langsStore || !langsStore.data || languagesIsLoading ? null :
          <div className="header-lang">
            <Select
              name="language"
              id="langselect"
              value={userStore.clientData.language.isoCode.toLowerCase()}
              bordered={false}
              onSelect={(value, option) => onChangeLanguage(value)}
            >
              {
                langsStore.data.map((lang, i) => {
                  return <Fragment key={i}><Select.Option value={lang.isoCode.toLowerCase()} id={"language_" + lang.id.toLowerCase()}>{lang.isoCode}</Select.Option></Fragment>
                })
              }

            </Select>
          </div>
      }</>
  }

  function onChangeLanguage(val) {
    var selectedNewLangData = langsStore.data.filter(lang => lang.isoCode.toLowerCase() === val.toLowerCase())[0]
    if (userStore.userData) {
      mutateLanguage.mutate({ iso: selectedNewLangData.isoCode })
    }
    console.log(selectedNewLangData)
    userStore.changeClientLanguage({ isoCode: selectedNewLangData.isoCode.toLowerCase(), id: selectedNewLangData.id, text: selectedNewLangData.name })
    localStorage.setItem("lang", (selectedNewLangData.isoCode).toLowerCase())
    i18n.changeLanguage(val);
    // setTimeout(function () {
    //   $(".overlay-load").addClass("hidden");
    // }, 1000);
  }

  useEffect(() => {
    if (!userStore.userData) { return }
    if (!userStore.userData.permissionsData) { return }
    hasPermission(userStore.userData.permissionsData, "PM_EDIT_SUBSCRIPTIONS", setPM_PlanEdit);
  }, [userStore.userData]);


  useEffect(() => {
    // LANGUAGE SET
    if (!userStore.userData) { return }
    if (!userStore.userData.language) { return }
    if (userStore.userData.language.isoCode !== undefined) {
      i18n.changeLanguage(userStore.userData.language.isoCode.toLowerCase());
    }

    if (userStore.userData === null) { return }
    if (userStore.userData === undefined) { return }
    if (userStore.userData.talentUser === undefined) {
      localStorage.setItem("userId", userStore.userData.id);
    }
  }, [userStore.userData]);


  return (
    <div className="header-wrapper">
      {
        userStore.clientData.loggedIn ?
          <div className="header header-account">
            <header className="header-desktop d-flex container">
              <div className="header-logo">
                {/* <Link to="/"> */}
                {
                  // handleEnvLogoIndicator() !== null ? handleEnvLogoIndicator() :
                  <img src={wiseLogo} alt="WiSE-Logo" />
                }
                {/* </Link> */}
              </div>
              <div className="overlay overlay-load hidden">
                <div className="no-data justify-content-center" style={{ height: "75vh" }}>
                  <Loading text={null} color="orange" fullscreen big />
                </div>
              </div>
              <div className="header-features d-flex w-75 align-items-center justify-content-end flex-row">
                <LanguageSelect />
                <div className='desktop-lg-only'>
                  <svg width="2" height="10" viewBox="0 0 1 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="0.5" x2="0.499999" y2="17.5" stroke="#808080" strokeLinecap="round" />
                  </svg>
                </div>
                {/* <div className="ml-4 bp"><a href={envir.Variables.WiseBlogUrl} className="font-special">{t("business_main_blog")}</a></div> */}
                {
                  !userStore.companyData ? null :
                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                      !PM_PlanEdit ? null :
                        <Link to="/company/plans" className="desktop-lg-only pointer hover ml-4"><span className="icon-upgrade icon fs-regular icon-nospace mr-2" /><span className="font-special">{t("business_main_upgrade")}</span></Link>
                }
                <div className="ml-4 desktop-lg-only pointer hover"
                  onClick={() => userStore.logout({ navigate })}>
                  <span className="icon-out icon fs-regular icon-nospace mr-2" /><span className="font-special">{t("business_main_logout")}</span>
                </div>
                <div className="ml-2 lg-only"><div onClick={() => userStore.toggleClientDataMobileMenuOverlay()} className={userStore.clientData.showMobileMenuOverlay ? "icon-cross" : "icon-burger"}></div></div>
              </div>
            </header>
            <OverlayHeaderMenu setPrevRoute={props.setPrevRoute} account />
          </div>
          :
          <div className="header header-account">
            <header className="header-desktop d-flex container">
              <div className="header-logo">
                {/* <Link to="/"> */}
                {
                  handleEnvLogoIndicator() !== null ? handleEnvLogoIndicator() :
                    <img src={wiseLogo} alt="WiSE-Logo" />
                }
                {/* </Link> */}
              </div>
              <div className="overlay overlay-load hidden">
                <div className="no-data justify-content-center" style={{ height: "75vh" }}>
                  <Loading text={null} color="orange" fullscreen big />
                </div>
              </div>
              <div className="header-features w-75 d-flex align-items-center justify-content-end flex-row">
                <LanguageSelect />
                <div className='desktop-lg-only'>
                  <svg width="2" height="10" viewBox="0 0 1 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="0.5" x2="0.499999" y2="17.5" stroke="#808080" strokeLinecap="round" />
                  </svg>
                </div>
                {/* <div className="ml-4 bp"><a href={envir.Variables.WiseBlogUrl} className="font-special">{t("business_main_blog")}</a></div> */}
                <div className="ml-4 bp"><Link to="/register" className="font-special">{t("business_main_register")}</Link></div>
                <div className="ml-4 bp"><Link to="/login" className="font-special">{t("business_main_login")}</Link></div>
                <div className="ml-3 lg-only"><div onClick={() => userStore.toggleClientDataMobileMenuOverlay()} className={userStore.clientData.showMobileMenuOverlay ? "icon-cross" : "icon-burger"}></div></div>
              </div>
            </header>
            <OverlayHeaderMenu />
          </div>
      }
      <HeaderAnnouncement data={announcement} />
    </div >
  );
}

export default Header;

// set logo in header to, easy indicator for the different environments
export function handleEnvLogoIndicator() {
  if (window.location.host.includes("wisebusinesstest.azurewebsites.net")) {
    return <span className='fw-bold fs-large'>TEST</span>
  } else if (window.location.host.includes("localhost")) {
    return <span className='fw-bold fs-large'>LOCAL</span>
  } else if (window.location.host.includes("wisebusinessdev.azurewebsites.net")) {
    return <span className='fw-bold fs-large color-red'>DEV</span>
  } else { return null }
}

// gets announcement alert that would be put in header - to share info with user like when maintenance etc
const useGetHeadAlert = (props) => {
  return useQuery({
    queryKey: ["getHeadAlert"],
    queryFn: () => axios.get("v2/select/generic/alert"),
    retry: 1,
    refetchOnWindowFocus: false,
    onSuccess: (res) => handleRequestSuccess({ response: res, callback: () => props.setAnnouncement(res.data.instance) }),
    onError: (res) => handleRequestError({ response: res })
  })
}
import { useMutation, useQuery } from "@tanstack/react-query"
import { Empty, Table, Tooltip } from "antd";
import Loading from "components/animations/loading";
import NoData from "components/layouts/no-data/nodata";
import Panel from "components/modules/panel/panel";
import { dateFormat } from "components/utils/methods/AppMethods";
import { getDate } from "components/utils/methods/MyMethods";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers"
import { useUserStore } from "components/utils/stores/userStore"
import { t } from "i18next";

export default function HotTalentPanel(props) {
    const userStore = useUserStore();

    const { data, isLoading, isError } = useGetHotTalents({
        ceKey: props.ceKey || null,
        filterPhaseId: props.filterPhaseId || null,
        filterUserId: props.filterUserId || null
    });
    // const data = {
    //     items: [
    //         {
    //             "sortIndex": 1,
    //             "jobName": "Audit Consultant",
    //             "totalApplicants": 6,
    //             "lastRateDate": "2023-11-17"
    //         },
    //         {
    //             "sortIndex": 2,
    //             "jobName": "Support Assistant",
    //             "totalApplicants": 2,
    //             "lastRateDate": "2023-10-11"
    //         },
    //         {
    //             "sortIndex": 3,
    //             "jobName": "Accountant",
    //             "totalApplicants": 12,
    //             "lastRateDate": "2022-03-24"
    //         }
    //     ]
    // }
    const mutateAlert = useMutation({
        mutationKey: ["mutateHotTalentAlert"],
        mutationFn: () => sendAxiosPostRequest({
            route: `v3/generic/wise/alerts/hotpool`,
            body: {
                BusinessId: userStore.companyData.id
            },
            hasAlert: true,
            submitMessage: t("business_dashboard_panel_hot_talent_alert_submit"),
            isSubmit: true,
            callback: () => {
                document.getElementById("hot-talent-alert").remove()
            }
        }),
        retry: false
    })
    const columns = [
        {
            title: t("business_dashboard_panel_hot_talent_table_head_jobname"),
            dataIndex: 'jobName',
            key: 'jobName',
        },
        {
            title: t("business_dashboard_panel_hot_talent_table_head_amount"),
            dataIndex: 'totalApplicants',
            key: 'totalApplicants',
        },
        {
            title: t("business_dashboard_panel_hot_talent_table_head_update_date"),
            dataIndex: 'lastRateDate',
            key: 'lastRateDate',
        },
    ]

    return <>
        {
            !data ? null :
                !data.items ? null :
                    <div className={`${'row'}`}>
                        <div className={`${'col gx-2'}`}>
                            <Panel
                                isLoading={isLoading}
                                isError={isError}
                                bodyStyle={{ padding: 0 }}
                                title={<div className="d-flex justify-content-between flex-row w-full">
                                    {
                                        data.items.length === 0 ? <div>{t("business_dashboard_panel_hot_talent_title")}</div> :
                                            <div>
                                                {t("business_dashboard_panel_hot_talent_title")} {data.total > 0 ? `(${data.amount}/${data.total})` : null} {data.percentage ? ` - ${data.percentage}%` : null}
                                            </div>
                                    }
                                    <div

                                        id="hot-talent-alert"
                                    >
                                        {
                                            mutateAlert.isLoading ? <Loading style={{ margin: 0 }} className="m-0 p-0" size="14px" type="circle" /> : <div className="d-flex align-items-center flex-row">
                                                <div
                                                    onClick={() => mutateAlert.isLoading ? null : mutateAlert.mutate()}
                                                    className="font-special link-generic">{t("business_dashboard_panel_hot_talent_alert")}
                                                </div>
                                                <Tooltip placement="bottom" className="mx-2" title={t("business_dashboard_panel_hot_talent_tooltip")}><span className="icon icon-help-empty icon-nospace fs-xregular color-darkgrey help" /></Tooltip>
                                            </div>
                                        }
                                    </div>
                                </div>}
                            >
                                <>
                                    {/* <div className="d-flex justify-content-end">
                                        <div className="btn btn-primary">{t("business_dashboard_panel_hot_talent_alert")}</div>
                                    </div> */}
                                    <Table locale={{
                                        emptyText: <>
                                            <Empty description={t("business_extra_no_data")} />
                                        </>
                                    }} className="m-3" pagination={false} columns={columns} dataSource={data.items} />

                                </>
                            </Panel>
                        </div>
                    </div>
        }
    </>
}

const useGetHotTalents = (props) => {
    const userStore = useUserStore();

    return useQuery({
        queryKey: ["getHotTalents"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/hotpool`,
            // route: `v4/business/select/businesses/${userStore.companyData.id}/dashboards/hotpool`,
            hasAlert: true,
            body: {
                CeKey: props.ceKey || null,
                BusinessId: userStore.companyData.id,
                FilterPhaseId: props.filterPhaseId || null,
                FilterUserId: props.filterUserId || null,
            },
            callback: (res) => {
                const data = res.data.instance.entries;
                var newObj = [];
                if (data) {
                    data.sort((a, b) => new Date(b.lastRateDate) - new Date(a.lastRateDate)).forEach(item => {
                        newObj.push({
                            key: item.sortIndex,
                            totalApplicants: item.totalApplicants,
                            jobName: item.jobName,
                            lastRateDate: getDate(item.lastRateDate, dateFormat),
                        })
                        // newObj.push({
                        //     key: item.sortIndex,
                        //     sortIndex: item.sortIndex,
                        //     email: <a href={`mailto:${item.email}`} className="link-generic">{item.email}</a>,
                        //     date: getDate(item.date, dateFormat),
                        // })
                    });
                }
                return {
                    ...res.data.instance,
                    items: newObj,
                };
            }
        }),
        enabled: !!(userStore.clientData.loggedIn && userStore.companyData.id),
        retry: false,
        refetchOnWindowFocus: false,
    })
}
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/animations/loading";
import AccordionPricing from "../../components/layouts/accordions/accordionPricing.jsx";
import NoData from "../../components/layouts/no-data/nodata";
import GuestRedirectLayout from "../../components/layouts/page-layouts/guest-redirect";
import { hasPermission } from "../../components/utils/methods/MyMethods";
import { usePlansStore } from "components/utils/stores/plansStore";
import LayoutCompany from "./layout-company";


export default function CompanyPlansPage(props) {
    // # STORES
    const userStore = useUserStore();
    const plansStore = usePlansStore();

    // # CLIENT STATES
    // PERMISSIONS
    const [PM_PlanView, setPM_PlanView] = useState(false);
    const [PM_PlanEdit, setPM_PlanEdit] = useState(false);

    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);

    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_SUBSCRIPTIONS", setPM_PlanView);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_SUBSCRIPTIONS", setPM_PlanEdit);

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
    }, [userStore.userData]);

    function getPlan() {
        if (!userStore.companyData) { return }
        return userStore.companyData.subscriptionData.name;
    }


    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GuestRedirectLayout>
            <LayoutCompany
                title={t("business_company_plans_title")}
                subtitle={t("business_company_plans_subtitle")}
            >

                <div className="mt-3">
                    <div>
                        {
                            !plansStore.data ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                !PM_PlanView ? <div className="d-flex justify-content-center my-5"><NoData wise="cross" text={t("business_extra_permission_required")} /></div> :
                                    (plansStore.data).length === 0 ? <NoData /> :
                                        Array.from(plansStore.data).map((item, i) => {
                                            if (!item.isVisible) { return }
                                            return <AccordionPricing
                                                accountType={!userStore.companyData ? null : userStore.companyData.subscriptionData.int1}
                                                data={item}
                                                PM_PlanEdit={PM_PlanEdit} />
                                        })
                        }
                    </div>
                </div>
            </LayoutCompany>
        </GuestRedirectLayout>
    );
}
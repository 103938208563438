import {
  CheckCircleOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import { Button, Collapse, Form, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea.js';
import { useUserStore } from 'components/utils/stores/userStore.jsx';
import { t } from 'i18next';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import NoData from '../../layouts/no-data/nodata.jsx';
import { handleRequestError, handleRequestSuccess, hasPermission } from '../../utils/methods/MyMethods.jsx';
import ReplyMessage from './ReplyMessage.jsx';
import useAddTipReply from 'components/requests/company/useAddTipReply.jsx';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

function TipsMessage(props) {
  // # STORES
  const userStore = useUserStore()

  // # CLIENT STATES
  const [expanded, setExpanded] = useState(false);
  const [PM_FeedbackReply, setPM_FeedbackReply] = useState(false);
  const [newTipMessage, setNewTipMessage] = useState(); // SET NEW TIP REPLY TEXT

  // # SERVER STATES 
  const mutateAddTipReply = useAddTipReply({
    setNewTipMessage, newTipMessage,
    getFeedbackMessages: props.getData,
  });
  const mutateFeedbackIsRead = useFeedbackIsRead({
    getData: props.getData,
  })

  // # UTILS
  var dateFormat = "";
  dateFormat = new Moment(props.data.tipCreatedOn);
  var local = dateFormat.utc(dateFormat).local();
  const threshold = 100;
  const replyMaxChar = 250;
  const { Panel } = Collapse;


  useEffect(() => {
    if (!userStore.userData) { return }
    if (!userStore.userData.permissionsData) { return }
    hasPermission(userStore.userData.permissionsData, "PM_EDIT_FEEDBACK", setPM_FeedbackReply);
  }, [userStore.userData]);

  return (
    <div className="my-2 feedback-item">

      <Collapse
        collapsible={Boolean(props.data.tipIsAnonymous) && props.data.isNoted ? "disabled" : "head"}
        accordion
        onChange={() => setExpanded(!expanded)}
        className={`bg-white accordion`}
        expandIconPosition="end"
      >
        <Panel header={
          <>
            <div className='time color-grey d-flex flex-row flex-wrap align-items-center'>
              {
                !Boolean(props.data.tipIsAnonymous) && props.data.answer === null ?
                  props.data.isNoted ? null :
                    <div className="d-flex align-items-center mr-2">
                      <ExclamationCircleOutlined className='color-red' />
                    </div> :
                  !props.data.answer ? null :
                    <div className="d-flex align-items-center mr-2">
                      <CheckCircleOutlined className='color-lime' />
                    </div>
              }
              <span className='fs-small font-special'>{local.format("DD/MM/YY")}</span>
              <span className="fs-small font-special mx-1">-</span>
              <span className='fs-small font-special'>
                {local.format("HH:mm")}
              </span>
              <div className='mx-2 d-inline-block'>
                {
                  Boolean(props.data.tipIsAnonymous) ?
                    <Tag>{t("business_main_guest")}</Tag> : null
                }
                {
                  !Boolean(props.data.tipIsAnonymous) && props.data.answer === null ?
                    <Tag color="error">{t("business_main_unanswered")}</Tag> : null
                }
                {
                  props.data.answer ? null :
                    props.data.isNoted ?
                      <Tag>{t("business_tips_tag_read")}</Tag> : null
                }
              </div>
            </div>
            <span className={"message"}>
              {
                Boolean(props.data.tipIsAnonymous) && props.data.isNoted ? props.data.feedback :
                  props.data.feedback.length <= threshold ? props.data.feedback :
                    !expanded ? (props.data.feedback).substring(0, threshold) + "..." : props.data.feedback
              }
            </span>
          </>
        }>

          {
            // IF YOU HAVE REPLIES SHOW NO INPUT
            props.data.answer !== null ? null :
              // IF IS GUEST - DONT SHOW ANYTHING
              Boolean(props.data.tipIsAnonymous) ?
                <div className="d-flex justify-content-start align-item flex-row my-2">
                  <Button
                    className='btn btn-secondary '
                    onClick={() => mutateFeedbackIsRead.mutate({ tipId: props.data.id })}
                    loading={mutateFeedbackIsRead.isLoading}
                  >
                    {t("business_tips_noted")}
                  </Button>
                </div>
                :
                !PM_FeedbackReply ?
                  <div className='py-4'>
                    <NoData wise="cross" text={t("business_extra_permission_required")} />
                  </div> :
                  <div className='reply-container pt-2'>
                    <Form
                      name="form-tip-reply"
                      className='my-2 p-2 message'
                      onFinish={() => mutateAddTipReply.mutate({ tipId: props.data.id })}
                      layout='vertical'
                    >
                      <TextArea showCount required
                        placeholder={t("business_hero_feedback_reply")}
                        maxLength={replyMaxChar}
                        onChange={(e) => setNewTipMessage(e.target.value)}
                        className='mb-5'
                      />
                      <div className="d-flex justify-content-end align-item flex-row my-2">
                        <Button
                          loading={mutateAddTipReply.isLoading}
                          htmlType='submit'
                          className='btn btn-primary mr-2'
                        >
                          {t("business_main_send")}
                        </Button>
                        {
                          props.data.isNoted ? null :
                            <Button
                              className='btn btn-secondary '
                              onClick={() => mutateFeedbackIsRead.mutate({ tipId: props.data.id })}
                              loading={mutateFeedbackIsRead.isLoading}
                            >
                              {t("business_tips_noted")}
                            </Button>
                        }
                      </div>

                    </Form>
                  </div>
          }
          {
            !props.data.answer ? null :
              <div className="mx-4">
                <ReplyMessage
                  message={props.data.answer}
                  date={props.data.replyCreatedOn}
                  prename={props.data.replyUserFirstName}
                />
              </div>
          }
        </Panel>
      </Collapse>
    </div>
  );
}

export default TipsMessage;

export function isGuest(id) {
  var isGuest = false;
  if (id === undefined) {
    isGuest = true;
  } else if (id === "00000000-0000-0000-0000-000000000000" || !id) {
    isGuest = true;
  }
  return isGuest
}


const useFeedbackIsRead = (props) => {
  const userStore = useUserStore();
  return useMutation({
    mutationKey: ["mutateFeedbackIsRead"],
    mutationFn: (propsFn) => axios.get(`v1/update/companies/${userStore.companyData.id}/tips/${propsFn.tipId}/noted`),
    retry: false,
    onSuccess: (res) => handleRequestSuccess({ response: res, isSubmit: true, hasAlert: true, callback: () => props.getData() }),
    onError: (res) => handleRequestError({ response: res, isSubmit: true, hasAlert: true }),
  })
}
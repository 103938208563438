import { useQuery } from "@tanstack/react-query";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";

export const useGetDbScoreEvolution = (propsFn) => {
    const userStore = useUserStore();
    const props = propsFn || {};

    return useQuery({
        queryKey: [props.key || 'getDbScoreEvolution'],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/scores/evolutions`,
            // route: `v3/business/select/businesses/${userStore.companyData.id}/dashboards/evolutions`,
            body: {
                CeKey: props.ceKey || null,
                Category: props.category || 'Information',
                WeekNotation: props.isWeekNotation || false,
                UseDivisions: props.useDivisions || false,
                BusinessId: userStore.companyData.id,
                FilterPhaseId: props.filterPhaseId || null,
                FilterUserId: props.filterUserId || null,
            },
        }),
        refetchOnWindowFocus: false,
        enabled: false,
    })
}
import { Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import AccordionCommunication from "components/layouts/accordions/accordion-communication";
import { scoreReactionIcon } from "components/utils/icons/icons";
import { validatorEmptyFormItem } from "components/utils/methods/AppMethods";
import { useState } from "react";
import { t } from "i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess, hasPermission } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";
import Loading from "components/animations/loading";
import NoData from "components/layouts/no-data/nodata";
import Message from "components/modules/message/message";

export default function ScoreReactionAccordion(props) {
    // # STORES
    const userStore = useUserStore();

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [reactionText, setReactionText] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [hasReactionText, setHasReactionText] = useState(false); // used to be able to show "cancel" button

    // # SERVER STATES
    const { isLoading, isRefetching, isError, refetch } = useGetScoreReaction({ setReactionText, form: form, setShowForm, setHasReactionText })
    const mutateScoreReaction = useScoreReaction({ reactionText, getData: refetch });
    const mutateDeleteScoreReaction = useDeleteScoreReaction({ getData: refetch });

    return <>
        <AccordionCommunication
            icon={scoreReactionIcon}
            title={t("business_home_accordion_score_title")}
        >
            <div className="mx-5 mb-5">
                {
                    isLoading || isRefetching ? <Loading center /> :
                        isError ? <NoData error /> :
                            <>
                                <p>{t("business_home_accordion_score_description")}</p>
                                {
                                    !showForm ?
                                        <Form
                                            form={form}
                                            name="form-score-reaction"
                                            onFinishFailed={(err) => console.error(err)}
                                            onFinish={() => mutateScoreReaction.mutate()}
                                        >
                                            <Form.Item
                                                name="reaction"
                                                help={false}
                                                rules={[{ required: true, message: false }, validatorEmptyFormItem("reaction")]}
                                            >
                                                <TextArea
                                                    showCount
                                                    placeholder={t("business_home_accordion_score_placeholder")}
                                                    maxLength={250}
                                                    onChange={(e) => setReactionText(e.target.value)}
                                                    className='mb-3'
                                                    disabled={!hasPermission(userStore.userData.permissionsData, "PM_EDIT_SCORE_REACTION") ? true : false}
                                                />
                                            </Form.Item>
                                            {
                                                !hasPermission(userStore.userData.permissionsData, "PM_EDIT_SCORE_REACTION") ? null :
                                                    <div className="d-flex align-items-center">
                                                        <Button
                                                            htmlType="submit"
                                                            loading={mutateScoreReaction.isLoading}
                                                            className="btn btn-primary mr-2 mb-2"
                                                        >
                                                            {t("business_main_save")}
                                                        </Button>
                                                        {
                                                            !hasReactionText ? null :
                                                                <div className="btn btn-secondary mr-2 mb-2" onClick={() => refetch()}>{t("business_main_cancel")}</div>
                                                        }
                                                    </div>
                                            }
                                        </Form> :
                                        <>
                                            <div className="mt-2">
                                                <Message
                                                    from={userStore.companyData.name}
                                                    message={reactionText}
                                                />
                                                {
                                                    !hasPermission(userStore.userData.permissionsData, "PM_EDIT_SCORE_REACTION") ? null :
                                                        <div className="d-flex justify-content-end">
                                                            <span onClick={() => { setShowForm(!showForm); form.setFieldValue("reaction", reactionText) }} className="fs-small color-grey mr-2 hover">{t("business_main_edit")}</span>
                                                            <span onClick={() => { mutateDeleteScoreReaction.mutate(); form.setFieldValue("reaction", ""); }} className="fs-small color-grey mr-2 hover">{t("business_main_delete")}</span>
                                                        </div>
                                                }
                                            </div>
                                        </>
                                }
                            </>
                }
            </div>
        </AccordionCommunication>
    </>
}

const useScoreReaction = (props) => {
    const userStore = useUserStore();

    return useMutation({
        mutationKey: ["mutateScoreReaction"],
        mutationFn: () => axios.post(`v1/update/companies/${userStore.companyData.id}/scorecomment`, { Message: props.reactionText }),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({ response: res, hasAlert: true, isSubmit: true, callback: () => props.getData() }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}

const useGetScoreReaction = (props) => {
    const userStore = useUserStore();

    return useQuery({
        queryKey: ["getScoreReaction"],
        queryFn: () => axios.get(`v1/companies/${userStore.companyData.id}/scorecomment`),
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                if (res.data.instance.scoreComment) {
                    props.form.setFieldsValue({ "reaction": res.data.instance.scoreComment })
                    props.setReactionText(res.data.instance.scoreComment)
                    props.setShowForm(true);
                    props.setHasReactionText(true);
                } else {
                    props.setShowForm(false)
                    props.setHasReactionText(false);
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

const useDeleteScoreReaction = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateDeleteScoreReaction"],
        mutationFn: () => axios.get(`v1/delete/companies/${userStore.companyData.id}/scorecomment`),
        retry: false,
        onSuccess: (res) => handleRequestSuccess({ response: res, hasAlert: true, isSubmit: true, callback: () => props.getData() }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true, isSubmit: true }),
    })
}
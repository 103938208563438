import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NoData from "../../components/layouts/no-data/nodata";
import { hasPermission } from "../../components/utils/methods/MyMethods";
import envir from "../../env.json";
import LayoutCompany from "./layout-company";


export default function CompanyUpgradePage(props) {
    // # STORES
    const userStore = useUserStore();

    // # UTILS
    const navigate = useNavigate();

    // # CLIENT STATES
    const [IHavePermission, setHasPermission] = useState(false);
    // PERMISSIONS
    const [PM_PlanView, setPM_PlanView] = useState(false);
    const [PM_PromiseView, setPM_PromiseView] = useState(false);
    const [PM_ProcedureView, setPM_ProcedureView] = useState(false);
    const [PM_RecruitmentHistoryView, setPM_RecruitmentHistoryView] = useState(false);

    function getPlan() {
        if (!userStore.companyData) { return }
        return userStore.companyData.subscriptionData.name;
    }

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_SUBSCRIPTIONS", setHasPermission, navigate);

        // PERMISSION_COMPANY_HANDLER
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_VALUES", setPM_PromiseView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_PROCEDURES", setPM_ProcedureView);
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_RECRUITHISTORY", setPM_RecruitmentHistoryView);
    }, [userStore.userData]);


    return (
        <GuestRedirectLayout redirectSmallSubsciption>
            <LayoutCompany
                title={t("business_company_plans_title")}
                subtitle={t("business_company_plans_subtitle")}
                showPlan
            >
                <div className="card">
                    {
                        IHavePermission ?
                            <div>
                                <p>{t("business_company_plans_upgrade_construction")}</p>
                                <a href={"mailto:" + envir.Variables.WiseEmail} className="mt-3"> <div className="btn btn-primary d-inline-block"> {t("business_main_contact_us")}</div></a>
                            </div> :
                            // NO PERMISSION
                            <div className="d-flex justify-content-center my-5"><NoData wise="cross" text={t("business_extra_permission_required")} /></div>
                    }
                </div>
            </LayoutCompany>
        </GuestRedirectLayout>
    );
}
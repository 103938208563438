import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { handleRequestError, handleRequestSuccess } from "components/utils/methods/MyMethods";
import { useUserStore } from "components/utils/stores/userStore";

const useEditAgencySectors = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateAgencySectors"],
        mutationFn: (propsFn) => {
            var localTags = []
            props.tags.forEach(element => {
                localTags = [...localTags, element.id]
            });

            return axios.post(`v2/update/companies/${userStore.companyData.id}/agencysectors`, {
                UserId: userStore.userData.id,
                AgencySectorIds: localTags,
            })
        },
        retry: false,
        onSuccess: (res) => handleRequestSuccess({
            response: res, hasAlert: true, isSubmit: true, callback: () => {
                if (props.getTags) {
                    setTimeout(() => {
                        props.getTags();
                    }, 200);
                }
            }
        }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true })
    })
}

export default useEditAgencySectors;
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Form, Input, Modal, Popconfirm, Tag, Tooltip } from "antd";
import ImgCrop from "antd-img-crop";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";
import InputPhone from "components/forms/input/input-ant-phone";
import PrefixIcon from "components/forms/input/prefix-icon";
import NoData from "components/layouts/no-data/nodata";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { FileUploadSingleConditionalsCheck } from "components/modules/upload-handlers/FileUploadSingle";
import useEditAccountAvatar from "components/requests/account/useEditAccountAvatar";
import useGetRecruiterInfo from "components/requests/recruiter/useGetRecruiterInfo";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import produce from "immer";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from '../../components/animations/loading';
import InputEmoji from "../../components/forms/input/input-text-emoji";
import { getBase64, handleRequestError, handleRequestSuccess, hasPermission } from "../../components/utils/methods/MyMethods";
import LayoutRecruiter from "./layout-recruiter";
import { toast } from "react-toastify";
import { handleUploadPicture } from "components/utils/methods/AppMethods";

export default function RecruiterBrandingInfoPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # UTILS
    const maxRecruiterTags = 5;
    const navigate = useNavigate();
    const editFormItem = (key, val, setInit) => {
        if (setInit) {
            form.setFieldsValue({
                [key]: val
            });
        }
        setFormObject(produce(draft => {
            draft[key] = val;
        }))
    }
    const editPhone = (val) => editFormItem("phone", val);
    const editAva = (val) => setFormObject(produce(draft => {
        draft.avatar = val;
    }))
    const editRecruiterMessage = (val) => editFormItem("message", val);

    // # CLIENT STATES
    const [form] = Form.useForm();
    const [formObject, setFormObject] = useState({
        avatar: null,
        tags: [],
        tagsInput: "",
        phone: "",
        email: "",
        message: "",
    })
    const [recruiterData, setRecruiterData] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showToggle, setShowToggle] = useState(false); // SHOW TOGGLE OPTION IF ADMIN

    // # SERVER STATES
    const { refetch: recruiterInfoRefetch, isLoading: recruiterInfoIsLoading, isRefetching: recruiterInfoIsRefetching, isError } = useGetRecruiterInfo({ setData: setRecruiterData });
    const mutateRecruiterTags = useEditRecruiterTags({ allTags: formObject.tags });
    const mutateRecruiterShareToggle = useEditRecruiterShareToggle({ setShowSettings, showSettings });
    const mutateRecruiterInfo = useEditRecruiterInfo({ formObject });
    const mutateAccountAvatar = useEditAccountAvatar({ setPreview: editAva })

    const onFinish = (file) => {
        if (file) {
            handleUploadPicture(file, 10, 0, 0, "business/user/avatar").then((result) => {
                mutateAccountAvatar.mutate({ logoUrl: result })
            }).catch(() => {
                toast.error(t("business_extra_general_error2"));
                return;
            });
        }
        else {
            toast.error(t("business_extra_general_error2"));
            return;
        }
    }

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!userStore.clientData.checkedRememberMe) { return }
        if (!userStore.companyData) { return }
        if (userStore.userData === null) { return } // SOMETIMES WE GET USERID NULL 
        recruiterInfoRefetch();

        if (userStore.userData.roleData.id === "cac9fd97-8842-46bf-bfc7-34c88b72c6b8") {
            setShowToggle(true);

            if (!userStore.companyData && userStore.companyData.subscriptionData.int1 === 10) {
                setShowToggle(false);
            }
        }

    }, [userStore.userData]);

    useEffect(() => {
        if (!recruiterData) { return }
        if (!recruiterData.user) { return }
        if (!recruiterData.user) { return }
        editFormItem("message", recruiterData.user.instance.bio, true)
        editFormItem("phone", recruiterData.user.instance.phone, true)
        editFormItem("email", recruiterData.user.instance.email, true)
        editFormItem("avatar", recruiterData.user.instance.logoUrl, true)

        var myTags = [];
        if (recruiterData.tags.instance !== null) {
            (recruiterData.tags.instance).forEach(tag => {
                myTags = [...myTags, { id: tag.id, value: tag.tag }];
            });
        }
        editFormItem("tags", myTags, true)

        if (recruiterData.user.instance.toggleRecruiter !== undefined) {
            setShowSettings(recruiterData.user.instance.toggleRecruiter);
        }

    }, [recruiterData]);

    function submitForm(event) {
        if (event.key === "Enter") { return }
        // EDIT RECRUITER INFO
        mutateRecruiterInfo.mutate()
        mutateRecruiterTags.mutate();
        setTimeout(() => {
            window.scrollTo(0, 0);
            recruiterInfoRefetch();
        }, 200)
    }

    return (
        <GuestRedirectLayout>
            <LayoutRecruiter
                title={t("business_profile_general_title")}
                subtitle={t("business_profile_general_subtitle")}
                isVisible={!recruiterData ? false : recruiterData.user.instance.toggleRecruiter}
            >
                <div className="card mt-3">
                    {
                        showToggle === false ? null :
                            recruiterData === false ? null :
                                recruiterData.user === undefined || recruiterData.user.instance === undefined ? null :
                                    <div className="my-3">
                                        <label className="input-label">
                                            <div className="label-text">{t("business_profile_general_panel_search_subtitle")}:</div>
                                            <div className="switch mt-1 ml-2">
                                                <input type="checkbox" checked={showSettings} onClick={(e) => mutateRecruiterShareToggle.mutate()} />
                                                <span className="slider round"></span>
                                            </div>
                                        </label>
                                    </div>
                    }
                    {
                        showToggle === false || recruiterData === false || showSettings === false ? null :
                            <hr className="mt-2 mb-5"></hr>
                    }
                    {
                        recruiterInfoIsLoading || recruiterInfoIsRefetching ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                            isError ? <NoData error className="mb-5" /> :
                                showSettings === false ? null :
                                    <div className="wrapper">
                                        {

                                            recruiterData === false ? null :
                                                <div className="mb-5">
                                                    <ImgCrop
                                                        cropShape="round"
                                                        aspect={1}
                                                        rotationSlider
                                                        maxZoom={8}
                                                        beforeCrop={async (file, filelist) => {
                                                            const result = await FileUploadSingleConditionalsCheck({
                                                                file: file,
                                                                imageTypes: ["image/jpeg", "image/png"],
                                                                maxSize: 1000,
                                                                CompanyId: userStore.companyData.id,
                                                            });
                                                            return result
                                                        }}
                                                        modalOk={t("business_main_save")}
                                                        modalCancel={t("business_main_cancel")}
                                                    >
                                                        <Dragger
                                                            name="file"
                                                            accept="image/png, image/jpeg"
                                                            className='upload-logo upload-avatar'
                                                            action={null}
                                                            onRemove={() => editAva(undefined)}
                                                            multiple={false}
                                                            showUploadList={false}
                                                            // onPreview={onPreview}
                                                            beforeUpload={(file) => { onFinish(file); return false; }}
                                                        >
                                                            <div className="flex flex-row align-items-center justify-content-center">
                                                                <div className="ant-upload-drag-icon logo-preview mx-4">

                                                                    {

                                                                        !formObject.avatar ?
                                                                            <span className="default icon-account fs-xxlarge d-flex align-items-center justify-content-center"></span> : <img
                                                                                alt="company-logo"
                                                                                // onClick={() => mutateCompanyLogo.mutate({ logo: "" })}
                                                                                style={{ padding: "0px" }}
                                                                                className='my-2'
                                                                                src={formObject.avatar} />
                                                                    }
                                                                    {
                                                                        !formObject.avatar ? null :
                                                                            <div
                                                                                onClick={(event) => event.stopPropagation()}
                                                                            >
                                                                                <Popconfirm
                                                                                    title={t("business_avatar_remove_overlay_title")}
                                                                                    description={t("business_avatar_remove_overlay_description")}
                                                                                    onConfirm={() => {
                                                                                        mutateAccountAvatar.mutate({ image: "" })
                                                                                    }}
                                                                                    okText={t("business_company_logo_remove_overlay_yes")}
                                                                                    cancelText={t("business_company_logo_remove_overlay_no")}
                                                                                    okButtonProps={{ className: "btn-primary btn d-inline-block", style: { minHeight: "15", minWidth: "auto", padding: "0px 15px" } }}
                                                                                    cancelButtonProps={{ className: "btn-secondary btn d-inline-block", style: { minHeight: "15", minWidth: "auto", padding: "0px 15px" } }}
                                                                                >
                                                                                    <div
                                                                                        className='link-generic relative'
                                                                                        style={{ zIndex: 100, userSelect: "contain" }}
                                                                                        onClick={(event) => {
                                                                                            event.stopPropagation();
                                                                                        }}
                                                                                    >
                                                                                        {t("business_main_delete")}
                                                                                    </div>
                                                                                </Popconfirm>
                                                                            </div>
                                                                    }

                                                                </div>
                                                                <div style={{ maxWidth: 500 }}>
                                                                    <p className="ant-upload-text mb-1" style={{ textAlign: "left" }}>{!formObject.avatar ? t("business_account_logo_upload") : t("business_account_logo_upload")}</p>
                                                                    <p className="ant-upload-hint mb-1" style={{ textAlign: "left" }}>{t("business_company_general_logo_allowed")}</p>
                                                                </div>
                                                            </div>
                                                        </Dragger>
                                                    </ImgCrop>
                                                </div>
                                        }
                                        {
                                            recruiterData === false ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                                <>
                                                    <Form
                                                        form={form}
                                                        layout="vertical"
                                                        name="form-recruiter-info"
                                                        onFinish={submitForm}
                                                    >
                                                        <>
                                                            <h3 className='mb-4 icon-width d-flex align-items-center mr-3'> <span className="icon-recruiter icon round lime"></span> {t("business_profile_general_panel_bio_title")}</h3>
                                                            <Form.Item
                                                                name="message"
                                                                label={t("business_profile_general_panel_bio_about_subtitle")}
                                                            >
                                                                <InputEmoji
                                                                    onChange={(e) => editFormItem("message", e.target.value)}
                                                                    form={form}
                                                                    setRecruiterMessage={editRecruiterMessage}
                                                                    value={formObject.message}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={t("business_profile_general_panel_bio_specialized_subtitle")}
                                                                name="tags2"
                                                            >
                                                                <>
                                                                    <Input
                                                                        onChange={(e) => editFormItem("tagsInput", e.target.value)}
                                                                        value={formObject.tagsInput}
                                                                        placeholder={t("business_main_keywords")}
                                                                        suffix={
                                                                            <Tooltip placement="left" overlayInnerStyle={{ fontSize: "14px" }} title={t("business_tooltip_profile_general_panel_bio_specialized")}>
                                                                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                                            </Tooltip>
                                                                        }
                                                                        onPressEnter={(formObject.tags).length < maxRecruiterTags ?
                                                                            (e) => {
                                                                                e.preventDefault();
                                                                                if ((e.target.value).trim().length === 0) { return }
                                                                                editFormItem("tags", [...formObject.tags, { id: null, value: e.target.value }]);
                                                                                editFormItem("tagsInput", "");
                                                                            }
                                                                            : null}
                                                                    />
                                                                    {
                                                                        formObject.tags.length === 0 ? null :
                                                                            <div className="mt-2">
                                                                                {
                                                                                    formObject.tags.map((tag, i) => {
                                                                                        return <Fragment key={i}>
                                                                                            <Tag hidden={false} closable value={tag.id} className="large" onClose={(e) => {
                                                                                                e.preventDefault();
                                                                                                editFormItem("tags", formObject.tags.filter(item => item.value !== tag.value))
                                                                                                editFormItem("tagsInput", "");
                                                                                            }}>{tag.value}</Tag>
                                                                                        </Fragment>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                    }
                                                                </>
                                                            </Form.Item>
                                                            <>
                                                                <h3 className='mb-4 icon-width d-flex align-items-center mr-3'> <span className="icon-mail-full fs-small icon round lime"></span> {t("business_profile_general_panel_contact_title")}</h3>
                                                                <div className="my-3 mb-2">
                                                                    <Form.Item
                                                                        name="email"
                                                                        label={t("business_main_email")}
                                                                        rules={[{ required: false, message: false, type: "email" }]}
                                                                    >
                                                                        <Input
                                                                            // onChange={(e) => editFormItem("email", e.target.value)}
                                                                            disabled={true}
                                                                            value={formObject.email}
                                                                            prefix={<PrefixIcon value="icon-mail fs-small" />}
                                                                            placeholder={t("business_extra_placeholder_email_business")}
                                                                            type="mail"
                                                                        />

                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name="phone"
                                                                        label={t("business_main_phone")}
                                                                    >

                                                                        <InputPhone
                                                                            name="phone"
                                                                            onChange={editPhone}
                                                                            form={form}
                                                                            defaultValue={!recruiterData ? "" : recruiterData.user.instance.phone}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </>
                                                        </>
                                                        <div className="btns-right mt-4">
                                                            <Button
                                                                htmlType="submit"
                                                                className="btn btn-primary d-inline-block"
                                                                loading={mutateRecruiterInfo.isLoading}
                                                            >
                                                                {t("business_main_save")}
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </>
                                        }
                                    </div>
                    }
                </div>
            </LayoutRecruiter>
        </GuestRedirectLayout>
    );
}

const useEditRecruiterTags = (props) => {
    const userStore = useUserStore();
    var arrayTags = [];

    if (props.allTags !== null) {
        (props.allTags).forEach((item, i) => {
            arrayTags.push(item.value);
        });
    }
    return useMutation({
        mutationKey: ["mutateRecruiterTags"],
        mutationFn: () => axios.post(`v2/update/users/${userStore.userData.id}/profile/${userStore.userData.id}/tags`, {
            Tags: arrayTags
        }),
        onSuccess: (res) => handleRequestSuccess({ response: res, hasAlert: true }),
        onError: (res) => handleRequestError({ response: res, hasAlert: true }),
    })
}

const useEditRecruiterShareToggle = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateRecruiterShareToggle"],
        mutationFn: () => axios.get(`v2/update/users/${userStore.userData.id}/profile/${userStore.userData.id}/visible/${!props.showSettings}`),
        onSuccess: (res) => handleRequestSuccess({
            response: res, callback: () => {
                props.setShowSettings(!props.showSettings)
            }
        }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

const useEditRecruiterInfo = (props) => {
    const userStore = useUserStore();
    return useMutation({
        mutationKey: ["mutateRecruiterInfo"],
        mutationFn: () => axios.post(`account/recruiter/update`, {
            UserId: userStore.userData.id,
            ProfileId: userStore.userData.id,
            Email: props.formObject.email,
            Phone: props.formObject.phone,
            Message: props.formObject.message
        }),
        onSuccess: (res) => handleRequestSuccess({ response: res, isSubmit: true, hasAlert: true }),
        onError: (res) => handleRequestError({ response: res, isSubmit: true, hasAlert: true }),
    })
}
import Moment from 'moment';

function ReplyMessage(props) {
  var dateFormat = new Moment(props.date);
  var local = dateFormat.utc(props.date).local()

  return (
    <div className="reply-container">
      <div className="message">
        <span>{!props.prename ? null : props.prename + " - "}{local.format("HH:mmu DD/MM/YYYY")}</span>
        <div className="w-100 disabled textarea" style={{ resize: "none" }} disabled>{props.message}</div>
      </div>
    </div>
    // <div className="feedback-reply">
    //   <div><span>{ dateFormat.format('DD/MM/YY') } </span> <span className="message">{ message }</span> <span>- { prename }, { positioning }</span></div>
    // </div>
  );
}

export default ReplyMessage;
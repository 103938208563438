import { Timeline, Divider } from "antd"
import NoData from "components/layouts/no-data/nodata"
import Message from "components/modules/message/message"
import Panel from "components/modules/panel/panel"
import { dateFormat } from "components/utils/methods/AppMethods"
import { getDate, getDateInFormat } from "components/utils/methods/MyMethods"
import { t } from "i18next"
import { Fragment, useEffect, useState } from "react"
import { CornerDownRight, MinusCircle, PlayCircle, PlusCircle, XCircle } from "react-feather"

export default function MessagesPanel(props) {
    const dateFormat = "MMMM 'YY";

    const [dates, setDates] = useState();

    useEffect(() => {
        if (!props.data || props.data.length <= 0) {
            return
        }
        var dates = [];
        props.data.forEach(element => {
            if (getDateInFormat(element.createdOn, dateFormat) && dates.filter(date => date.label === getDateInFormat(element.createdOn, dateFormat)).length <= 0) {
                dates.push({ raw: element, label: getDateInFormat(element.createdOn, dateFormat) });
            }
        });
        setDates(dates)
    }, [props.data])

    return <>
        <Panel
            {...props.panelProps}
            scroll
            scrollHeight={400}
            title={t("business_dashboard_panel_messages_title")}
        >
            <div className="d-inline-block" style={{ width: "100%" }}>
                {
                    !dates || dates.length <= 0 ? <NoData wise="sad" className="my-4" center /> :
                        dates.sort((a, b) => a.raw.createdOn > b.raw.createdOn ? -1 : 1).map(date => {
                            var positiveItems = [];
                            var negativeItems = [];
                            props.data.filter(element => getDateInFormat(element.createdOn, dateFormat) === date.label).map(element => {
                                if (element.isPositive) {
                                    positiveItems.push(element)
                                } else {
                                    negativeItems.push(element)
                                }
                            })
                            return <>
                                <Divider orientationMargin="20" orientation="middle"><h2 className="fw-regular color-darkgrey mb-0" style={{ fontSize: 22 }}>  {date.label}</h2></Divider>

                                <div className="flex flex-row flex-wrap justify-content-center mb-5" style={{ width: "100%" }}>
                                    <div className="w-50 px-2">
                                        <div className=" my-4 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                            <PlusCircle color="green" className="mx-3" size={50} />
                                            <h3 style={{ color: "#008000" }} className="fw-medium color-darkgrey mb-0">{t("business_main_positive")} {`(${positiveItems.filter(item => item.feedback.trim() !== 0).length})`}</h3>
                                        </div>

                                        {
                                            positiveItems && positiveItems.length > 0 ? positiveItems.sort((a, b) => a.createdOn > b.createdOn ? 1 : -1).map((message, i) => message.feedback && message.feedback.trim().length > 0 &&
                                                <Message key={i} message={message.feedback} from={`${t("business_main_anonymous_user")} - ${getDateInFormat(message.createdOn, `hh:mmu DD/MM/YYYY`)}`} />) :
                                                <NoData className="py-4" wise="smile" text={t("business_dashboard_panel_messages_none")} />
                                        }


                                    </div>
                                    <div className="w-50 b px-2">
                                        <div className=" my-4 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                            <MinusCircle color="red" className="mx-3" size={50} />
                                            <h3 style={{ color: "#FF0000" }} className="fw-medium color-darkgrey mb-0">{t("business_main_negative")} {`(${negativeItems.filter(item => item.feedback.trim() !== 0).length})`}</h3>
                                        </div>
                                        {
                                            negativeItems && negativeItems.length > 0 ? negativeItems.sort((a, b) => a.createdOn > b.createdOn ? 1 : -1).map((message, i) => message.feedback && message.feedback.trim().length > 0 &&
                                                <Message key={i} message={message.feedback} from={`${t("business_main_anonymous_user")} - ${getDateInFormat(message.createdOn, `hh:mmu DD/MM/YYYY`)}`} />) :
                                                <NoData className="py-4" wise="smile" text={t("business_dashboard_panel_messages_none")} />
                                        }

                                    </div>
                                </div>
                            </>
                        })
                }
            </div>
        </Panel>
    </>
}
// export default function MessagesPanel(props) {
//     const dateFormat = "MMMM 'YY"

//     return <>
//         <Panel
//             {...props.panelProps}
//             scroll
//             scrollHeight={400}
//             title={t("business_dashboard_panel_messages_title")}
//         >
//             <div className="d-inline-block" style={{ width: "100%" }}>
//                 {
//                     !props.data || props.data.length <= 0 ? <NoData wise="sad" className="my-4" center /> :
//                         <div className="flex flex-row flex-wrap justify-content-center" style={{ width: "100%" }}>
//                             <div className="w-50 px-2">
//                                 <div className="block text-center my-4">
//                                     <PlusCircle color="green" size={50} />
//                                 </div>
//                                 {
//                                     props.data && <Timeline
//                                         id="feedback-timeline"
//                                         mode="left"
//                                         style={{ maxWidth: 900 }}
//                                         className="mx-10 my-4"
//                                         items={
//                                             props.data && props.data.filter((message) => Boolean(message.isPositive) === true).sort((a, b) => a.createdOn > b.createdOn ? -1 : 1).map((message, i) => {
//                                                 if (message.feedback.trim().length === 0) { return }
//                                                 const prevItemDate = i === 0 ? null : props.data.filter((message) => Boolean(message.isPositive) === true).sort((a, b) => a.createdOn > b.createdOn ? -1 : 1)[i - 1].createdOn;
//                                                 return {
//                                                     color: message.replyText ? 'green' : 'gray',
//                                                     label: prevItemDate && getDateInFormat(prevItemDate, dateFormat) === getDateInFormat(message.createdOn, dateFormat) ? null : getDateInFormat(message.createdOn, dateFormat),
//                                                     children: <p style={{ wordBreak: "break-word", display: "inline" }}>{message.feedback}</p>,
//                                                 }
//                                             })
//                                         }
//                                     />
//                                 }

//                             </div>
//                             <div className="w-50 b px-2">
//                                 <div className="block text-center my-4">
//                                     <MinusCircle color="red" size={50} />
//                                 </div>
//                                 {
//                                     props.data && <Timeline
//                                         id="feedback-timeline"
//                                         mode="left"
//                                         style={{ maxWidth: 900 }}
//                                         className="mx-10 my-4"
//                                         items={
//                                             props.data && props.data.filter((message) => Boolean(message.isPositive) === false).sort((a, b) => a.createdOn > b.createdOn ? -1 : 1).map((message, i) => {
//                                                 if (message.feedback.trim().length === 0) { return }
//                                                 const prevItemDate = i === 0 ? null : props.data.filter((message) => Boolean(message.isPositive) === false).sort((a, b) => a.createdOn > b.createdOn ? -1 : 1)[i - 1].createdOn;
//                                                 return {
//                                                     color: message.replyText ? 'green' : 'gray',
//                                                     label: prevItemDate && getDateInFormat(prevItemDate, dateFormat) === getDateInFormat(message.createdOn, dateFormat) ? null : getDateInFormat(message.createdOn, dateFormat),
//                                                     children: <p style={{ wordBreak: "break-word", display: "inline" }}>{message.feedback}</p>,
//                                                 }
//                                             })
//                                         }
//                                     />

//                                 }
//                             </div>
//                         </div>
//                 }
//             </div>
//         </Panel>
//     </>
// }
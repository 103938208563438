import { hasUserPermission } from "components/utils/methods/AppMethods";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from 'i18next';
import { Link, useNavigate } from "react-router-dom";
import envir from '../../../env.json';

function OverlayHeaderMenu(props) {
    // # STORES
    const userStore = useUserStore();

    // # UTILS
    const navigate = useNavigate();

    return (
        <div className={userStore.clientData.showMobileMenuOverlay ? "overlay" : "hidden"} id="overlay-header">
            <div className="overlay-content">
                {
                    props.account === undefined ?
                        <div className="menu-items">
                            <Link to="/login" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-account icon">
                                <span>{t("business_main_login")}</span>
                            </div></Link>
                            <Link to="/register" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-account-plus icon">
                                <span>{t("business_main_register")}</span>
                            </div></Link>
                            <div className="menu-item icon-blog icon"><a href={envir.Variables.WiseBlogUrl} className="">{t("business_main_blog")}</a></div>
                        </div>
                        :
                        <div className="menu-items">
                            <Link to="/wise" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-house icon">
                                <span>{t("business_main_home")}</span>
                            </div></Link>
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/dashboard" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-stats icon">
                                            <span>{t("business_main_dashboard")}</span>
                                        </div></Link>
                            }
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-company icon">
                                            <span>{t("business_main_company")}</span>
                                        </div></Link>
                            }
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company/team" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                            <span>{t("business_company_sidebar_team_title")}</span>
                                        </div></Link>
                            }
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company/promise" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                            <span>{t("business_company_promise_title")}</span>
                                        </div></Link>
                            }
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company/procedure" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                            <span>{t("business_company_sidebar_process_title")}</span>
                                        </div></Link>
                            }
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company/recruitment-history" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                            <span>{t("business_company_sidebar_history_title")}</span>
                                        </div></Link>
                            }
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company/recruitment" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                            <span>{t("business_company_sidebar_agency_title")}</span>
                                        </div></Link>
                            }
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company/share" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                            <span>{t("business_company_sidebar_share_title")}</span>
                                        </div></Link>
                            }
                            {

                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company/plans" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                            <span>{t("business_company_sidebar_plans_title")}</span>
                                        </div></Link>
                            }
                            {
                                !hasUserPermission(userStore, "PM_VIEW_JOBS") ? null :
                                    !userStore.companyData ? null :
                                        userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                            <Link to="/jobs" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-briefcase icon">
                                                <span>{t("business_main_jobs")}</span>
                                            </div></Link>
                            }
                            <Link to="/account" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-person icon">
                                <span>{t("business_account_sidebar_account_title")}</span>
                            </div></Link>
                            <Link to="/account/security" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                <span>{t("business_account_sidebar_security_title")}</span>
                            </div></Link>
                            <Link to="/account/alerts" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                <span>{t("business_account_sidebar_notifications_title")}</span>
                            </div></Link>
                            <Link to="/recruiter-branding/overview" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-recruiter-brand icon">
                                <span>{t("business_profile_title")}</span>
                            </div></Link>
                            <Link to="/recruiter-branding/info" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-arrow2-right icon">
                                <span>{t("business_profile_general_title")}</span>
                            </div></Link>
                            {/* FEATURE-JOBFAIRS */}
                            {
                                !hasUserPermission(userStore, "PM_JobEventsView") ? null :
                                    <Link to="/jobfairs" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-jobfair icon">
                                        <span>{t("business_main_jobfairs")}</span>
                                    </div></Link>
                            }

                            {
                                !userStore.companyData ?
                                    <Link to="/company/plans/upgrade" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-upgrade icon">
                                        <span>{t("business_main_upgrade")}</span>
                                    </div></Link>
                                    :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ? null :
                                        <Link to="/company/plans/upgrade" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-upgrade icon">
                                            <span>{t("business_main_upgrade")}</span>
                                        </div></Link>
                            }
                            <div className="menu-item icon-blog icon"><a href={envir.Variables.WiseBlogUrl} className="">{t("business_main_blog")}</a></div>

                            {/* <Link to="/profile/notifications" onClick={() => userStore.toggleClientDataMobileMenuOverlay()}><div className="menu-item icon-alert icon">
                                <span>{t("profile_menu_notifications")}</span>
                            </div></Link> */}
                            <Link to="/logout" onClick={() => {
                                userStore.logout({ navigate });

                                userStore.toggleClientDataMobileMenuOverlay()
                            }}><div className="menu-item icon-out icon">
                                    <span>{t("business_main_logout")}</span>
                                </div></Link>
                        </div>
                }
            </div>
        </div>
    );
}

export default OverlayHeaderMenu;
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import useGetRecruiterInfo from "components/requests/recruiter/useGetRecruiterInfo";
import { useUserStore } from "components/utils/stores/userStore";
import * as htmlToImage from "html-to-image";
import { t } from "i18next";
import Moment from 'moment';
import { createRef, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import whyImg from '../../assets/images/why_recruit.png';
import Loading from '../../components/animations/loading';
import InputCopy from "../../components/forms/input/input-copy";
import ShareScreenshot from '../../components/layouts/recruiter/share-screenshot';
import { handleRequestError, handleRequestSuccess, hasPermission } from "../../components/utils/methods/MyMethods";
import envir from '../../env.json';
import LayoutRecruiter from "./layout-recruiter";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";

export default function RecruiterBrandingRecommendationsPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # UTILS
    const navigate = useNavigate();
    const ref = createRef(null);

    // # CLIENT STATES
    const [recruiterData, setRecruiterData] = useState(false);
    const [recommendationsData, setRecommendationsData] = useState(null);

    // # SERVER STATES
    const { data: recruiterLikesData, isLoading: recruiterLikesIsLoading, isError: recruiterLikesIsError } = useQuery({
        queryKey: ["getRecruiterLikes"],
        queryFn: () => sendAxiosPostRequest({
            route: "v5/select/user/likes",
            body: {
                UserId: userStore.userData.id
            },
            callback: (res) => {
                if (res.data.instance && res.data.instance.length > 0) {
                    return res.data.instance
                } else {
                    return null
                }
            }
        }),
        enabled: !!(userStore.userData.id),
        refetchOnWindowFocus: false
    })
    const { } = useGetRecruiterInfo({ setData: setRecruiterData });
    const { isLoading: recruiterHistoryIsLoading, isRefetching: recruiterHistoryIsRefetching, isError } = useGetRecruiterHistory({ setData: setRecommendationsData });


    const createFileName = (extension = "", ...names) => {
        if (!extension) {
            return "";
        }
        return `${names.join("")}.${extension}`;
    };

    const takeScreenShot = async (node) => {
        const dataURI = await htmlToImage.toJpeg(node);
        return dataURI;
    };

    const download = (image, { name = "WiSE-recruiter-profile", extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GuestRedirectLayout>
            <LayoutRecruiter
                title={t("business_profile_recommendations_title")}
                subtitle={t("business_profile_recommendations_subtitle")}
                isVisible={!recruiterData ? false : recruiterData.user.instance.toggleRecruiter}
            >
                <div className="mt-3">
                    <div className="card card-scroll mb-4">
                        <div className="wrapper">
                            <div className="mt-4 mx-4">
                                <h3 className='mb-4 d-flex align-items-center mr-3'>{t("business_profile_expressions_panel_received_title")}</h3>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-evenly mb-5 w-full">
                            {
                                recruiterLikesData && recruiterLikesData.length > 0 && <>
                                    <div className="d-flex align-items-center flex-row">
                                        <span className="fs-large mr-3">{(recruiterLikesData.find((item) => item.name && item.name.includes("_like")) &&
                                            recruiterLikesData.find((item) => item.name && item.name.includes("_like")).amount || 0)}</span>
                                        <span>
                                            {likeFullSvg}
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center flex-row">
                                        <span className="fs-large mr-3">{(recruiterLikesData.find((item) => item.name && item.name.includes("_heart")) &&
                                            recruiterLikesData.find((item) => item.name && item.name.includes("_heart")).amount || 0)}</span>
                                        <span>
                                            {heartFullSvg}
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center flex-row">
                                        <span className="fs-large mr-3">{(recruiterLikesData.find((item) => item.name && item.name.includes("_service")) &&
                                            recruiterLikesData.find((item) => item.name && item.name.includes("_service")).amount || 0)}</span>
                                        <span>
                                            {serviceFullSvg}
                                        </span>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="card card-scroll mb-4">
                        <div className="wrapper">
                            <div className="mt-4 mx-4">
                                <h3 className='mb-4 icon-width d-flex align-items-center mr-3'> <span className="icon-history icon round lime"></span> {t("business_profile_recommendations_panel_received_title")}</h3>
                            </div>
                        </div>
                        <div className="scroll scroll-small px-4">
                            <div className="my-3">
                                {
                                    recruiterHistoryIsLoading || recruiterHistoryIsRefetching ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                        isError || !recommendationsData ?
                                            <p className="font-special text-center">{t("business_recruiter_branding_no_recommendations")}</p> :
                                            (recommendationsData.sort(function (a, b) {
                                                var dateA = new Date(a.createdOn), dateB = new Date(b.createdOn)
                                                return dateB - dateA
                                            })).map(recommendation => {
                                                var dateFormat = new Moment(recommendation.createdOn);
                                                var local = dateFormat.utc(recommendation.createdOn).local()
                                                return <div className="color-darkgrey mb-1"><span className="font-special">{local.format("DD/MM/YYYY")}</span> {" - " + recommendation.recommendationTypeName} <span className="float-right"><u>@{recommendation.companyName}</u></span></div>
                                            })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="wrapper">
                            <h3 className='mb-4 icon-width d-flex align-items-center'> <span className="icon-recruiter icon round lime"></span> {t("business_unknown2")}</h3>
                            {
                                !userStore.companyData ? null :
                                    userStore.companyData.subscriptionData.int1 === 10 && userStore.companyData.subscriptionData.guid1 === "0e63167e-848d-42aa-9365-864318aa89d0" ?
                                        <div>
                                            <InputCopy
                                                value={envir.Variables.WiSEHomeUrl + "/recruiter/" + userStore.companyData.shortCode}
                                            />
                                            <div className="d-flex flex-wrap mt-3">
                                                <div onClick={downloadScreenshot} className="btn btn-primary d-inline-block mb-2">{t("business_profile_recommendations_panel_screenshot_download")}</div>
                                                <a href={envir.Variables.WiSEHomeUrl + "/recruiter/" + userStore.companyData.shortCode} target="_blank" className="btn btn-primary d-inline-block ml-2 mb-2" rel="noreferrer">{t("business_hero_quick_wise_title")}</a>
                                            </div>
                                        </div> :
                                        null
                            }
                            <div className="mb-20 mt-10">
                                <div className="my-5 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                    <div className="mr-20">
                                        <img src={whyImg} alt="why-image" style={{ maxWidth: "500px" }} />
                                    </div>
                                    <div>
                                        <h3>{t("business_profile_recommendations_panel_screenshot_info_title")}</h3>
                                        <div className="d-flex flex-column">
                                            <div className="icon-width"><span className="icon icon-check lime" />{t("business_profile_recommendations_panel_screenshot_info_item1")}</div>
                                            <div className="icon-width"><span className="icon icon-check lime" />{t("business_profile_recommendations_panel_screenshot_info_item2")}</div>
                                            <div className="icon-width"><span className="icon icon-check lime" />{t("business_profile_recommendations_panel_screenshot_info_item3")}</div>
                                        </div>
                                        <div className="d-inline-block btn btn-primary mt-3" onClick={downloadScreenshot}>
                                            {t("business_profile_recommendations_panel_screenshot_download")}
                                        </div>
                                    </div>
                                </div>
                                <hr className="mt-10 mb-4" />
                                <div className="d-flex justify-content-center mt-4">
                                    <ShareScreenshot
                                        data={recruiterData}
                                        recommendations={recommendationsData}
                                        ref={ref}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </LayoutRecruiter>
        </GuestRedirectLayout>
    );
}

const useGetRecruiterHistory = (props) => {
    const userStore = useUserStore();
    return useQuery({
        queryKey: ["getRecruiterHistory"],
        queryFn: () => axios.post("account/recruiter/history", {
            UserId: userStore.userData.id,
            ProfileId: userStore.userData.recruiterProfileId,
            LanguageId: userStore.clientData.language.id,
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: !!userStore.userData,
        onSuccess: (res) => handleRequestSuccess({ response: res, callback: () => props.setData(res.data.instance) }),
        onError: (res) => handleRequestError({ response: res }),
    })
}

// like
const likeFullSvg = <svg width="55" height="44" viewBox="0 0 55 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.804 16.9047H46.8655C47.7481 16.9293 48.6103 17.1194 49.3803 17.459C50.15 17.7986 50.8048 18.2779 51.2901 18.8567C51.8134 19.4092 52.1615 20.0513 52.3058 20.7302C52.4498 21.409 52.3862 22.105 52.1198 22.7607L45.4826 38.3769C44.6532 40.3289 44.1 41.4134 40.2286 41.4134C34.6977 41.4134 28.6139 39.8951 23.3595 38.5937L19.2114 37.7262V16.4709C23.6215 12.8355 27.5157 8.8358 30.8262 4.54186C31.1028 3.67429 32.2088 3.02363 33.3151 2.58984H36.6334C37.7397 2.80674 38.5691 3.45742 39.3989 4.10808L39.952 6.92767L35.804 16.9047ZM5.93754 17.5553H13.6806V34.9065C13.6806 36.057 13.0979 37.1604 12.0607 37.9739C11.0235 38.7873 9.6167 39.2444 8.14985 39.2444C6.683 39.2444 5.2762 38.7873 4.23898 37.9739C3.20176 37.1604 2.61905 36.057 2.61905 34.9065V20.158C2.61905 18.6398 4.00175 17.5553 5.93754 17.5553Z" fill="#39B4DA" stroke="#2595E1" />
</svg>;

// service
const serviceFullSvg = <svg width="49" height="47" viewBox="0 0 49 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.0702 32.9276C27.2198 32.9318 30.3135 32.1111 32.9964 30.551L34.2041 29.8486L33.4196 28.6835L31.3484 25.6079L30.6761 24.6094L29.6409 25.2112C27.9795 26.1771 26.0489 26.6928 24.0721 26.6896L24.0683 26.6899C22.0915 26.6928 20.1608 26.1771 18.4994 25.2112L17.4643 24.6094L16.7919 25.6079L14.7209 28.6835L13.9363 29.8486L15.1441 30.551C17.8269 32.1111 20.9206 32.9318 24.0702 32.9276ZM32.3542 29.4188L31.2886 30.1543L31.2027 30.0266C31.5947 29.8397 31.9787 29.6371 32.3542 29.4188ZM32.3542 29.4188L31.7118 28.2866C31.6832 28.3033 31.6543 28.32 31.6254 28.3367L32.3542 29.4188ZM15.7863 29.4188L16.515 28.3367C16.4861 28.32 16.4573 28.3036 16.4286 28.2866L15.7863 29.4188ZM15.7863 29.4188L16.8517 30.1543L16.9377 30.0266C16.5457 29.8397 16.1616 29.6371 15.7863 29.4188ZM22.7807 42.5023V43.8078H24.0702C28.1606 43.8078 32.1257 42.4232 35.2197 39.8909C38.0111 37.6059 39.9255 34.5225 40.6589 31.1142H41.6539C42.9978 31.1142 44.3068 30.6199 45.2884 29.7089C46.2736 28.7945 46.851 27.5286 46.851 26.182V18.9285C46.851 17.582 46.2736 16.3161 45.2884 15.4017C44.3068 14.4907 42.9978 13.9963 41.6539 13.9963H40.6587C39.9247 10.5884 38.0103 7.50527 35.2189 5.22088C32.1249 2.68907 28.1603 1.30469 24.0702 1.30469C19.9802 1.30469 16.0153 2.68907 12.9215 5.22088C10.13 7.50527 8.21556 10.5884 7.4817 13.9963H6.48646C5.14257 13.9963 3.8336 14.4907 2.8521 15.4017C1.86683 16.3161 1.28949 17.582 1.28949 18.9285V26.182C1.28949 27.5286 1.86683 28.7945 2.8521 29.7089C3.8336 30.6199 5.14257 31.1142 6.48646 31.1142H12.3477H13.6372V29.8086V17.1152C13.6372 14.6143 14.7066 12.1902 16.6525 10.3841C18.6022 8.57452 21.2687 7.54057 24.0702 7.54057C26.8716 7.54057 29.5382 8.57452 31.4879 10.3841C33.4337 12.1902 34.5032 14.6143 34.5032 17.1152V27.9952C34.5032 30.4961 33.4337 32.9203 31.4879 34.7264C29.5382 36.5359 26.8716 37.5699 24.0702 37.5699H22.7807V38.8754V42.5023Z" fill="#0FD817" />
    <path d="M24.0702 32.9276C27.2198 32.9318 30.3135 32.1111 32.9964 30.551L34.2041 29.8486L33.4196 28.6835L31.3484 25.6079L30.6761 24.6094L29.6409 25.2112C27.9795 26.1771 26.0489 26.6928 24.0721 26.6896L24.0683 26.6899C22.0915 26.6928 20.1608 26.1771 18.4994 25.2112L17.4643 24.6094L16.7919 25.6079L14.7209 28.6835L13.9363 29.8486L15.1441 30.551C17.8269 32.1111 20.9206 32.9318 24.0702 32.9276ZM24.0702 32.9276C24.0708 32.9276 24.0696 32.9276 24.0702 32.9276ZM24.0702 32.9276L24.0702 31.622M32.3542 29.4188L31.2886 30.1543L31.2027 30.0266C31.5947 29.8397 31.9787 29.6371 32.3542 29.4188ZM32.3542 29.4188L31.7118 28.2866C31.6832 28.3033 31.6543 28.32 31.6254 28.3367L32.3542 29.4188ZM15.7863 29.4188L16.515 28.3367C16.4861 28.32 16.4573 28.3036 16.4286 28.2866L15.7863 29.4188ZM15.7863 29.4188L16.8517 30.1543L16.9377 30.0266C16.5457 29.8397 16.1616 29.6371 15.7863 29.4188ZM22.7807 42.5023V43.8078H24.0702C28.1606 43.8078 32.1257 42.4232 35.2197 39.8909C38.0111 37.6059 39.9255 34.5225 40.6589 31.1142H41.6539C42.9978 31.1142 44.3068 30.6199 45.2884 29.7089C46.2736 28.7945 46.851 27.5286 46.851 26.182V18.9285C46.851 17.582 46.2736 16.3161 45.2884 15.4017C44.3068 14.4907 42.9978 13.9963 41.6539 13.9963H40.6587C39.9247 10.5884 38.0103 7.50527 35.2189 5.22088C32.1249 2.68907 28.1603 1.30469 24.0702 1.30469C19.9802 1.30469 16.0153 2.68907 12.9215 5.22088C10.13 7.50527 8.21556 10.5884 7.4817 13.9963H6.48646C5.14257 13.9963 3.8336 14.4907 2.8521 15.4017C1.86683 16.3161 1.28949 17.582 1.28949 18.9285V26.182C1.28949 27.5286 1.86683 28.7945 2.8521 29.7089C3.8336 30.6199 5.14257 31.1142 6.48646 31.1142H12.3477H13.6372V29.8086V17.1152C13.6372 14.6143 14.7066 12.1902 16.6525 10.3841C18.6022 8.57452 21.2687 7.54057 24.0702 7.54057C26.8716 7.54057 29.5382 8.57452 31.4879 10.3841C33.4337 12.1902 34.5032 14.6143 34.5032 17.1152V27.9952C34.5032 30.4961 33.4337 32.9203 31.4879 34.7264C29.5382 36.5359 26.8716 37.5699 24.0702 37.5699H22.7807V38.8754V42.5023Z" stroke="#2DB221" />
</svg>;

// heart
const heartFullSvg = <svg width="50" height="42" viewBox="0 0 50 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.4211 17.6358C29.0498 17.2803 28.5463 17.0806 28.0213 17.0806C27.4966 17.0806 26.9932 17.2803 26.6219 17.6358L25.2222 18.9746C24.4754 19.6654 23.4752 20.0477 22.4371 20.039C21.399 20.0304 20.4059 19.6315 19.6718 18.9284C18.9377 18.2252 18.5213 17.2739 18.5123 16.2795C18.5033 15.2851 18.9024 14.3271 19.6236 13.6119L30.7692 2.93197C33.4082 2.35707 36.169 2.59368 38.6592 3.60814C41.1495 4.6226 43.2426 6.36329 44.6408 8.5827C46.039 10.8021 46.6711 13.3872 46.4471 15.9705C46.2232 18.5538 45.1547 21.0037 43.3937 22.9719L39.2205 27.0204L29.4211 17.6358ZM6.40214 6.46047C8.45064 4.49854 11.1301 3.25571 14.0072 2.93294C16.8844 2.61017 19.7912 3.22631 22.2575 4.68177L15.5008 11.1557C14.0374 12.5546 13.2032 14.4463 13.1765 16.4265C13.1498 18.4067 13.9327 20.3184 15.3579 21.7531C16.783 23.1878 18.7374 24.0317 20.8035 24.1045C22.8696 24.1773 25.2119 24.2466 26.7432 22.9161H27.5029L35.0997 29.9271L26.7 37.973C25.9575 38.6838 24.9506 39.0834 23.9007 39.0834C22.8508 39.0834 21.8439 38.6838 21.1014 37.973L6.40017 23.8911C3.98717 21.5796 2.63159 18.4446 2.63159 15.1758C2.63159 11.907 3.98914 8.77199 6.40214 6.46047Z" fill="#FD2222" stroke="#B64040" />
</svg>;